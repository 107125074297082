
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import $ from 'jquery';
import logoff from "images/turn-off.png"

export interface CheckoutFailedProps {
  className?: string;
  statusText?: string;
  navigateHome?: () => void;
}

const CheckoutFailed: FC<CheckoutFailedProps> = ({ className = "", statusText, navigateHome }) => {

  return (
    <div
      className={`font nc-PageAbout overflow-hidden relative font xl:pb-32 lg:pb-32 md:pb-24 pb-32 ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Travel Mart</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />


      {/* <div className=" center">
            <img className="w-16 text-center center" src={logoff} alt="" />
          </div> */}
      <div className="text-center fonts xl:text-2xl lg:text-4xl md:text-2xl text-xl xl:px-0 lg:px-0 md:px-0 px-4 text-red-600">
        {statusText}. Please try again
      </div>
      <br />
      <div className="text-center">
        <button className="px-8 py-3 rounded-full bg-sky-600 text-white text-sm cursor-pointer" type="button" onClick={navigateHome}>Back to Home</button>
      </div>
    </div>
  );
};

export default CheckoutFailed;
