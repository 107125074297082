import React, { useEffect, useState } from 'react'
import trawelmart from "images/trawelmart.png"
import './preloaderStyle.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import CheckoutSuccess from './CheckoutSuccess';
import CheckoutFailed from './CheckoutFailed';
import { error } from 'jquery';
import errorr from "images/errorr.png";
import cancelled from "images/cancelled.png"

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

function CheckoutStatus() {

    const query = useQuery();
    const status = query.get('status');
    const id = query.get('id');
    const date = query.get('date')?.replaceAll("_", ":");

    const [isLoading, setIsLoading] = useState(true);
    const [details, setDetails] = useState(null);

    const navigateToProfile = () => {
        (window as any).location = '/author';
    };

    const navigateToHome = () => {
        (window as any).location = '/';
    };

    useEffect(() => {
        if (status == "Success") {
            axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getTransactionDetails/" + id)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.status) {
                            setDetails(response.data.transaction);
                            setIsLoading(false);
                        } else {
                            setDetails(null);
                            setIsLoading(false);
                        }
                    }
                });
        }
        else {
            setIsLoading(false);
        }
    }, [status]);

    if (isLoading) {
        return (
            <div className="preloader">
                <div className="preloader-content">
                    <img src={trawelmart} alt="Logo" className="preloader-logo" />
                    <div className="spinner"></div>
                    <p className="status-text">Processing payment. Please wait</p>
                </div>
            </div>
        );
    }

    if (details && status == "Success") {
        return (
            <CheckoutSuccess details={details} transactionDate={date} navigateToProfile={navigateToProfile} />
        );
    }
    else {
        if (status == "Failed") {
            return (
                <>
                <div className="font flex content-center items-center justify-center space-y-0 py-24 xl:pt-48 pt-40 lg:space-y-0">
                    <div className='justify-center text-center items-center center'>
                        <img className="w-16 text-center xl:pb-4" src={cancelled} alt="" />
                        <CheckoutFailed statusText='Payment transaction is failed.' navigateHome={navigateToHome} />
                    </div>
                </div>
                </>
            );
        }
        else if (status == "Cancelled") {
            return (
                <>
                    <div className="font flex content-center items-center justify-center space-y-0 py-24 xl:pt-48 pt-40 lg:space-y-0">
                        <div className='justify-center text-center items-center center'>
                            <img className="w-16 text-center xl:pb-4" src={cancelled} alt="" />
                            <CheckoutFailed statusText='User cancelled the transaction.' navigateHome={navigateToHome} />
                        </div>
                    </div>
                </>
            );
        }
        else {
            return (
                <>
                    <div className="font flex content-center items-center justify-center space-y-0 py-24 xl:pt-52 pt-40 lg:space-y-0">
                        <div className='justify-center text-center items-center center'>
                            <img className="w-16 text-center xl:pb-4" src={errorr} alt="" />
                            <CheckoutFailed statusText='Security Error. Illegal access detected.' navigateHome={navigateToHome} />
                        </div>
                    </div>
                </>

            );
        }
        return (
            <div>
                <h1>Checkout Status</h1>
                <p>Status: {status}</p>
                <p>ID: {id}</p>
                <p>Date: {date}</p>
            </div>
        );
    }

}

export default CheckoutStatus;
