import { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Dialog, Transition } from "@headlessui/react";
import Heading2 from "components/Heading/Heading2";
import FlightCard, { FlightCardProps } from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Badge from "shared/Badge/Badge";
import FlightCard2, { FlightCard2Props } from "components/FlightCard/FlightCard2";
import axios from 'axios';
import Accordion from "containers/ListingDetailPage/listing-stay-detail/Accordion";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment'

export interface SectionGridFilterCardProps {
  className?: string;
}

const DEMO_DATA: FlightCardProps["data"][] = [
  {
    id: "1",
    price: "$1,80,000",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      name: "Emirates",
    },
  },

  {
    id: "1",
    price: "$1,50,000",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      name: "Emirates",
    },
  },
]

const DEMO_DATA1: FlightCard2Props["data"][] = [
  {
    id: "1",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      name: "Emirates",
    },
    day: "",
    days: "Dubai Holidays 12Days 11 Nights Tour Package",
    price: "$2,80,000",
    text: "",
    icons: "string",

  },
]

const accordionData3 = [
  {
    title: 'Itinerary Daywise',
    content: [
      <ul key="list" className="pl-2">
        <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
          <ul className=" p-6">
            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
              Making the finest for you!.
            </li>
            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
              We are trying to reserve the airplane tickets for this trip. We will update it here, as we are done.
            </li>
            <li className="lg:text-base md:text-base text-sm  font-normal">
              Post Booking, you will be notified through your contact details about all tour related updates.
            </li>
          </ul>
        </div>
      </ul>,
    ],
  },
]

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Singapore - Tokyo"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            22 flights
            <span className="mx-2">·</span>
            round trip
            <span className="mx-2">·</span>2 Guests
          </span>
        }
      />
    </div>
  );
};
export interface AuthorPageProps {
  className?: string;
}
const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  let [categories] = useState(["Stays", "Experiences", "Car for rent"]);
  let [isOpen, setIsOpen] = useState(false);


  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber);
  };


  type YourTravelerType = {
    id: any; // Or string, depending on the type of 'id'.
    salutation: any;
    name: any;
    gender: any;
    age: any;
    passport_number: any;
    passport_expiry: any;
    passport_issue_place: any;
    passport_file: any;
    aadhar_file: any;
    pan_file: any;
    // Add other properties if available.
  };
  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState('');
  const [userMobileNumber, setUserMobileNumber] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [address, setAddress] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [addressState, setAddressState] = useState('');
  const [country, setCountry] = useState('');
  const [userId, setUserId] = useState('');
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [travelers, setTravelers] = useState<YourTravelerType[]>([]);
  const [travelerSalutation, setTravelerSalutation] = useState('');
  const [travelerName, setTravelerName] = useState('');
  const [travelerGender, setTravelerGender] = useState('');
  const [travelerAge, setTravelerAge] = useState('');
  const [editTravelerSalutation, setEditTravelerSalutation] = useState('');
  const [editTravelerName, setEditTravelerName] = useState('');
  const [editTravelerGender, setEditTravelerGender] = useState('');
  const [editTravelerAge, setEditTravelerAge] = useState('');
  const [editTravelerId, setEditTravelerId] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [passportExpiry, setPassportExpiry] = useState('');
  const [passportIssue, setPassportIssue] = useState('');
  const [editTravelerPassportNumber, setEditTravelerPassportNumber] = useState('');
  const [editTravelerPassportExpiry, setEditTravelerPassportExpiry] = useState('');
  const [editTravelerPassportIssue, setEditTravelerPassportIssue] = useState('');
  const [passportFile, setPassportFile] = useState<File[]>([]);
  const [selectedPassportFile, setSelectedPassportFile] = useState('');
  const [aadharFile, setAadharFile] = useState<File[]>([]);
  const [selectedAadharFile, setSelectedAadharFile] = useState('');
  const [panFile, setPanFile] = useState<File[]>([]);
  const [selectedPanFile, setSelectedPanFile] = useState('');
  const [travelerPassportNumber, setTravelerPassportNumber] = useState('');
  const [travelerPassportExpiry, setTravelerPassportExpiry] = useState('');
  const [travelerPassportIssue, setTravelerPassportIssue] = useState('');
  const [travelerPassportFile, setTravelerPassportFile] = useState<File[]>([]);
  const [travelerSelectedPassportFile, setTravelerSelectedPassportFile] = useState('');
  const [travelerAadharFile, setTravelerAadharFile] = useState<File[]>([]);
  const [travelerSelectedAadharFile, setTravelerSelectedAadharFile] = useState('');
  const [travelerPanFile, setTravelerPanFile] = useState<File[]>([]);
  const [travelerSelectedPanFile, setTravelerSelectedPanFile] = useState('');
  const [upcomingTours, setUpcomingTours] = useState([]);
  const [completedTours, setCompletedTours] = useState([]);
  const [cancelledTours, setCancelledTours] = useState([]);
  const [cartTour, setCartTour] = useState(null);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  let [inOpen, setOpen] = useState(false);
  function close() {
    setOpen(false);
  }
  function open() {
    setOpen(true);
  }
  let [getOpen, changeOpen] = useState(false);
  function closePassword() {
    changeOpen(false);
  }
  function openPassword() {
    changeOpen(true);
  }

  const formatPrice = (price: string) => {
    // Convert the input string to a number
    const amount = parseFloat(price.replace(/,/g, ""));

    // Ensure amount is a valid number
    if (isNaN(amount)) {
      return "Invalid input";
    }

    // Use toLocaleString to format the number with Indian numbering system
    const formattedAmount = amount.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return formattedAmount;
  };


  let [onOpen, closeOpen] = useState(false);
  let [onEditOpen, closeEditOpen] = useState(false);
  function add() {
    closeOpen(false);
  }
  function edit() {
    closeEditOpen(false);
    setTravelerSelectedPassportFile('');
    setTravelerSelectedAadharFile('');
    setTravelerSelectedPanFile('');
  }
  function remove() {
    closeOpen(true);
  }

  function editData(index: any) {
    if (travelers) {
      console.log(travelers[index]);
      setEditTravelerId(travelers[index].id);
      setEditTravelerSalutation(travelers[index].salutation)
      setEditTravelerName(travelers[index].name)
      setEditTravelerGender(travelers[index].gender)
      setEditTravelerAge(travelers[index].age)
      travelers[index].passport_number ? setEditTravelerPassportNumber(travelers[index].passport_number) : setEditTravelerPassportNumber('');
      travelers[index].passport_issue_place ? setEditTravelerPassportIssue(travelers[index].passport_issue_place) : setEditTravelerPassportIssue('');
      travelers[index].passport_expiry ? setEditTravelerPassportExpiry(travelers[index].passport_expiry) : setEditTravelerPassportExpiry('');
      travelers[index].passport_file ? setTravelerSelectedPassportFile(travelers[index].passport_file) : setTravelerSelectedPassportFile('');
      travelers[index].aadhar_file ? setTravelerSelectedAadharFile(travelers[index].aadhar_file) : setTravelerSelectedAadharFile('');
      travelers[index].pan_file ? setTravelerSelectedPanFile(travelers[index].pan_file) : setTravelerSelectedPanFile('');
      closeEditOpen(true);
    }
  }



  const [isAccountVisible, setIsAccountVisible] = useState(true);
  const [isTourVisible, setIsTourVisible] = useState(false);
  const [isWishlistVisible, setIsWishlistVisible] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [isBackVisible, setIsBackVisible] = useState(false);

  const showAccount = () => {
    setIsWishlistVisible(false);
    setIsTourVisible(false);
    setIsAccountVisible(true);
    setIsCartVisible(false);
  };

  const showTour = () => {
    setIsTourVisible(true);
    setIsAccountVisible(false);
    setIsWishlistVisible(false);
    setIsCartVisible(false);
  };

  const showWhislist = () => {
    setIsTourVisible(false);
    setIsAccountVisible(false);
    setIsWishlistVisible(true);
    setIsCartVisible(false);
  };
  const showCart = () => {
    setIsTourVisible(false);
    setIsAccountVisible(false);
    setIsWishlistVisible(false);
    setIsCartVisible(true);
  };

  const [showButton, setShowButton] = useState(true);
  const [showButton1, setShowButton1] = useState(true);
  const [showButton2, setShowButton2] = useState(true);
  const [showButton3, setShowButton3] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [showContent1, setShowContent1] = useState(false);
  const [showContent2, setShowContent2] = useState(false);
  const [showContent3, setShowContent3] = useState(false);
  const [showBox, setShowBox] = useState(true);

  const toggleContent = () => {
    setShowButton(!showButton);
    setShowContent(!showContent);
    setShowBox(!showBox);
  };

  const toggleContent1 = () => {
    setShowButton1(!showButton1);
    setShowContent1(!showContent1);
    setShowBox(!showBox);
  };

  const toggleContent2 = () => {
    setShowButton2(!showButton2);
    setShowContent2(!showContent2);
    setShowBox(!showBox);
  };

  const reset = () => {
    setShowButton(true);
    setShowButton1(true);
    setShowButton2(true);
    setShowBox(true);
    setShowContent(false);
    setShowContent1(false);
    setShowContent2(false);
  };

  function extractFileName(input: any) {
    if (typeof input === 'string') {
      if (input.startsWith('[') && input.endsWith(']')) {
        // Value is enclosed in square brackets and double quotes, parse it
        const parsedValue = JSON.parse(input);
        return parsedValue[0];
      } else {
        // Value is not enclosed in square brackets and double quotes
        return input;
      }
    } else {
      // Handle other data types, e.g., if `input` is not a string
      return input.join(", ");
    }
  }

  useEffect(() => {

    const userDataJSON = localStorage.getItem('loggedInUser');

    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      setUserData(userData);
      setUserName(userData.name);
      setUserEmail(userData.email);
      setUserMobileNumber(userData.mobile);
      setUserId(userData.id);
      axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getUserProfile/" + userData.id)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              setDateOfBirth(response.data.user.date_of_birth);
              setGender(response.data.user.gender);
              setMaritalStatus(response.data.user.marital_status);
              setAddress(response.data.user.address);
              setPinCode(response.data.user.pincode);
              setAddressState(response.data.user.state);
              setCountry(response.data.user.country);
              response.data.user.passport_number ? setPassportNumber(response.data.user.passport_number) : setPassportNumber('');
              response.data.user.passport_expiry ? setPassportExpiry(response.data.user.passport_expiry) : setPassportExpiry('');
              response.data.user.passport_issue_place ? setPassportIssue(response.data.user.passport_issue_place) : setPassportIssue('');
              response.data.user.aadhar_file ? setSelectedAadharFile(extractFileName(response.data.user.aadhar_file)) : setSelectedAadharFile('');
              response.data.user.passport_file ? setSelectedPassportFile(extractFileName(response.data.user.passport_file)) : setSelectedPassportFile('');
              response.data.user.pan_file ? setSelectedPanFile(extractFileName(response.data.user.pan_file)) : setSelectedPanFile('');
            }
          }
        })

      axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getTravellers/" + userData.id)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              setTravelers(response.data.travelers);
            }
          }
        })

      axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getUpcomingTours/" + userData.id)
        .then((response) => {
          if (response.status == 200) {
            setUpcomingTours(response.data.upcoming_tours);
          }
        });

      axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getCompletedTours/" + userData.id)
        .then((response) => {
          if (response.status == 200) {
            setCompletedTours(response.data.completed_tours);
          }
        })

      axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getCancelledTours/" + userData.id)
        .then((response) => {
          if (response.status == 200) {
            setCancelledTours(response.data.cancelled_tours);
          }
        })
    }
    else {
      console.log("No details in local storage");
    }

    if (localStorage.getItem("packageId") !== null) {
      axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getTourDetail/" + localStorage.getItem("packageId"))
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              setCartTour(response.data.tour);
            }
          }
        })
    }

  }, []);

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [pricingData, setPricingData] = useState(null);

  function ircloseModal() {
    setSelectedItemId(null);
    setPricingData(null);
  }

  const iropenModal = async (itemId: any) => {
    await setSelectedItemId(itemId);
  };

  const fetchPriceData = async (itemId: any) => {
    try {
      const response = await axios.get(
        "https://admin.trawelmart.com/admin/public/index.php/api/getPricingListByPackageForWebsite/" +
        itemId
      );
      if (response.status == 200) {
        const data = response.data;
        setPricingData(data);
      }
    } catch (error) {
      console.error("Error fetching additional data:", error);
      throw error; // You might want to handle the error appropriately in your application
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      if (selectedItemId !== null) {
        await fetchPriceData(selectedItemId);
      }
    };

    fetchData();
  }, [selectedItemId]);

  function updateProfile() {
    if (userName == '') {
      toast.error("Please enter the user name");
    }
    else if (dateOfBirth == '') {
      toast.error("Please enter the date of birth");
    }
    else if (gender == '') {
      toast.error("Please select the gender");
    }
    else if (address == '') {
      toast.error("Please enter the address");
    }
    else if (pinCode == '') {
      toast.error("Please enter the pincode");
    }
    else if (addressState == '') {
      toast.error("Please select the state");
    }
    else if (country == '') {
      toast.error("Please select the country");
    }
    else if (passportNumber == '') {
      toast.error("Please enter the passport number");
    }
    else if (passportExpiry == '') {
      toast.error("Please select the passport expiry date");
    }
    else if (passportIssue == '') {
      toast.error("Please select the passport issue place");
    }
    else if (selectedPassportFile == '') {
      toast.error("Please select the passport file");
    }
    else if (selectedAadharFile == '') {
      toast.error("Please select the aadhar file");
    }
    else if (selectedPanFile == '') {
      toast.error("Please select the pan file");
    }
    else {
      const requestData = { name: userName, date_of_birth: dateOfBirth, gender: gender, marital_status: maritalStatus, address: address, pincode: pinCode, state: addressState, country: country, user_id: userId, passport_number: passportNumber, passport_expiry: passportExpiry, passport_issue_place: passportIssue, passport_file: selectedPassportFile, aadhar_file: selectedAadharFile, pan_file: selectedPanFile };
      axios.post("https://admin.trawelmart.com/admin/public/index.php/api/updateUserProfile", requestData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            if (response.data.status) {
              toast.success(response.data.message);
              (window as any).location = '/author';
            }
            else {
              toast.error(response.data.message);
            }
          }
        });
    }
  }

  function addTraveler() {
    if (travelerSalutation == "") {
      toast.error("Please select salutation");
    }
    else if (travelerName == '') {
      toast.error("Please enter the traveler name");
    }
    else if (travelerGender == '') {
      toast.error("Please select the traveler gender");
    }
    else if (travelerAge == '') {
      toast.error("Please enter the traveler age");
    }
    else if (travelerPassportNumber == '') {
      toast.error("Please enter the passport number");
    }
    else if (travelerPassportExpiry == '') {
      toast.error("Please enter the passport expiry date");
    }
    else if (travelerPassportIssue == '') {
      toast.error("Please select the passport issue place");
    }
    else if (travelerSelectedPassportFile == '') {
      toast.error("Please select the passport file");
    }
    else if (travelerSelectedAadharFile == '') {
      toast.error("Please select the aadhar file");
    }
    else if (travelerSelectedPanFile == '') {
      toast.error("Please select the pan file");
    }
    else {
      const requestData = { salutation: travelerSalutation, name: travelerName, gender: travelerGender, age: travelerAge, user_id: userId, passport_number: travelerPassportNumber, passport_expiry: travelerPassportExpiry, passport_issue_place: travelerPassportIssue, passport_file: extractFileName(travelerSelectedPassportFile), aadhar_file: extractFileName(travelerSelectedAadharFile), pan_file: extractFileName(travelerSelectedPanFile) };
      axios.post("https://admin.trawelmart.com/admin/public/index.php/api/addTraveler", requestData)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              toast.success(response.data.message);
              (window as any).location = '/author';
            }
          }
        });
    }
  }

  function updateTraveler() {
    if (editTravelerSalutation == "") {
      toast.error("Please select salutation");
    }
    else if (editTravelerName == '') {
      toast.error("Please enter the traveler name");
    }
    else if (editTravelerGender == '') {
      toast.error("Please select the traveler gender");
    }
    else if (editTravelerAge == '') {
      toast.error("Please enter the traveler age");
    }
    else if (editTravelerPassportNumber == '') {
      toast.error("Please enter the passport number");
    }
    else if (editTravelerPassportExpiry == '') {
      toast.error("Please enter the passport expiry date");
    }
    else if (editTravelerPassportIssue == '') {
      toast.error("Please select the passport issue place");
    }
    else if (travelerSelectedPassportFile == '') {
      toast.error("Please select the passport file");
    }
    else if (travelerSelectedAadharFile == '') {
      toast.error("Please select the aadhar file");
    }
    else if (travelerSelectedPanFile == '') {
      toast.error("Please select the pan file");
    }
    else {
      const requestData = { salutation: editTravelerSalutation, name: editTravelerName, gender: editTravelerGender, age: editTravelerAge, user_id: userId, id: editTravelerId, passport_number: editTravelerPassportNumber, passport_expiry: editTravelerPassportExpiry, passport_issue_place: editTravelerPassportIssue, passport_file: extractFileName(travelerSelectedPassportFile), aadhar_file: extractFileName(travelerSelectedAadharFile), pan_file: extractFileName(travelerSelectedPanFile) };
      console.log(requestData);
      axios.post("https://admin.trawelmart.com/admin/public/index.php/api/updateTraveler", requestData)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              setTravelerSelectedPassportFile('');
              setTravelerSelectedAadharFile('');
              setTravelerSelectedPanFile('');
              toast.success(response.data.message);
              (window as any).location = '/author';
            }
          }
        });
    }
  }

  function logout() {
    localStorage.setItem("isLoggedIn", JSON.stringify(false));
    localStorage.removeItem("loggedInUser");
    (window as any).location = "/";
  }

  const handlePassportFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPassportFile([...passportFile, ...Array.from(e.target.files)]);
    }
    else {
      setPassportFile([]);
    }
  }

  const handleTravelerPassportFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setTravelerPassportFile([...travelerPassportFile, ...Array.from(e.target.files)]);
    }
    else {
      setTravelerPassportFile([]);
    }
  }

  const handleAadharFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAadharFile([...aadharFile, ...Array.from(e.target.files)]);
    }
    else {
      setAadharFile([]);
    }
  }

  const handleTravelerAadharFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setTravelerAadharFile([...travelerAadharFile, ...Array.from(e.target.files)]);
    }
    else {
      setTravelerAadharFile([]);
    }
  }

  const handlePanFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPanFile([...panFile, ...Array.from(e.target.files)]);
    }
    else {
      setPanFile([]);
    }
  }

  const handleTravelerPanFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setTravelerPanFile([...travelerPanFile, ...Array.from(e.target.files)]);
    }
    else {
      setTravelerPanFile([]);
    }
  }

  useEffect(() => {

    const uploadPassportFile = async () => {
      const formData = new FormData();

      for (const file of passportFile) {
        formData.append('images[]', file);
      }

      try {
        const response = await axios.post("https://admin.trawelmart.com/admin/public/index.php/api/uploadUserPassportFile", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.status) {
          console.log(response.data.files);
          setSelectedPassportFile(extractFileName(response.data.files));
          setPassportFile([]);
          const realElement = document.getElementById("passport_file_holder_real");
          const passportImageElement = document.getElementById("passport_image_file") as HTMLImageElement;
          const hiddenElement = document.getElementById("passport_file_holder_hidden");

          if (realElement) {
            realElement.style.display = "none";
          }

          if (passportImageElement) {
            passportImageElement.src = "https://admin.trawelmart.com/admin/public/" + response.data.files;
          }

          if (hiddenElement) {
            hiddenElement.style.display = "block";
          }
        }
        else {
          toastr.error("Some error occurred");
        }
      }
      catch (error) {
        console.error("Upload failed ", error);
      }
    }

    if (passportFile.length > 0) {
      uploadPassportFile();
    }
  }, [passportFile]);

  useEffect(() => {

    const uploadTravelerPassportFile = async () => {
      const formData = new FormData();

      for (const file of travelerPassportFile) {
        formData.append('images[]', file);
      }

      try {
        const response = await axios.post("https://admin.trawelmart.com/admin/public/index.php/api/uploadTravelerPassportFile", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.status) {
          console.log(response.data.files);
          setTravelerSelectedPassportFile(extractFileName(response.data.files));
          setTravelerPassportFile([]);
          const realElement = document.getElementById("traveler_passport_file_holder_real");
          const TravelerPassportImageElement = document.getElementById("traveler_passport_image_file") as HTMLImageElement;
          const hiddenElement = document.getElementById("traveler_passport_file_holder_hidden");

          if (realElement) {
            realElement.style.display = "none";
          }

          if (TravelerPassportImageElement) {
            TravelerPassportImageElement.src = "https://admin.trawelmart.com/admin/public/" + response.data.files;
          }

          if (hiddenElement) {
            hiddenElement.style.display = "block";
          }
        }
        else {
          toastr.error("Some error occurred");
        }
      }
      catch (error) {
        console.error("Upload failed ", error);
      }
    }

    if (travelerPassportFile.length > 0) {
      uploadTravelerPassportFile();
    }
  }, [travelerPassportFile]);

  useEffect(() => {

    const uploadAadharFile = async () => {
      const formData = new FormData();

      for (const file of aadharFile) {
        formData.append('images[]', file);
      }

      try {
        const response = await axios.post("https://admin.trawelmart.com/admin/public/index.php/api/uploadUserAadharFile", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.status) {
          console.log(response.data.files);
          setSelectedAadharFile(extractFileName(response.data.files));
          setAadharFile([]);
          const realElement = document.getElementById("aadhar_file_holder_real");
          const aadharImageElement = document.getElementById("aadhar_image_file") as HTMLImageElement;
          const hiddenElement = document.getElementById("aadhar_file_holder_hidden");

          if (realElement) {
            realElement.style.display = "none";
          }

          if (aadharImageElement) {
            aadharImageElement.src = "https://admin.trawelmart.com/admin/public/" + response.data.files;
          }

          if (hiddenElement) {
            hiddenElement.style.display = "block";
          }
        }
        else {
          toastr.error("Some error occurred");
        }
      }
      catch (error) {
        console.error("Upload failed ", error);
      }
    }

    if (aadharFile.length > 0) {
      uploadAadharFile();
    }
  }, [aadharFile]);

  useEffect(() => {

    const uploadTravelerAadharFile = async () => {
      const formData = new FormData();

      for (const file of travelerAadharFile) {
        formData.append('images[]', file);
      }

      try {
        const response = await axios.post("https://admin.trawelmart.com/admin/public/index.php/api/uploadTravelerAadharFile", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.status) {
          console.log(response.data.files);
          setTravelerSelectedAadharFile(extractFileName(response.data.files));
          setTravelerAadharFile([]);
          const realElement = document.getElementById("traveler_aadhar_file_holder_real");
          const travelerAadharImageElement = document.getElementById("traveler_aadhar_image_file") as HTMLImageElement;
          const hiddenElement = document.getElementById("traveler_aadhar_file_holder_hidden");

          if (realElement) {
            realElement.style.display = "none";
          }

          if (travelerAadharImageElement) {
            travelerAadharImageElement.src = "https://admin.trawelmart.com/admin/public/" + response.data.files;
          }

          if (hiddenElement) {
            hiddenElement.style.display = "block";
          }
        }
        else {
          toastr.error("Some error occurred");
        }
      }
      catch (error) {
        console.error("Upload failed ", error);
      }
    }

    if (travelerAadharFile.length > 0) {
      uploadTravelerAadharFile();
    }
  }, [travelerAadharFile]);

  useEffect(() => {

    const uploadPanFile = async () => {
      const formData = new FormData();

      for (const file of panFile) {
        formData.append('images[]', file);
      }

      try {
        const response = await axios.post("https://admin.trawelmart.com/admin/public/index.php/api/uploadUserPanFile", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.status) {
          console.log(response.data.files);
          setSelectedPanFile(extractFileName(response.data.files));
          setPanFile([]);
          const realElement = document.getElementById("pan_file_holder_real");
          const panImageElement = document.getElementById("pan_image_file") as HTMLImageElement;
          const hiddenElement = document.getElementById("pan_file_holder_hidden");

          if (realElement) {
            realElement.style.display = "none";
          }

          if (panImageElement) {
            panImageElement.src = "https://admin.trawelmart.com/admin/public/" + response.data.files;
          }

          if (hiddenElement) {
            hiddenElement.style.display = "block";
          }
        }
        else {
          toastr.error("Some error occurred");
        }
      }
      catch (error) {
        console.error("Upload failed ", error);
      }
    }

    if (panFile.length > 0) {
      uploadPanFile();
    }
  }, [panFile]);

  useEffect(() => {

    const uploadTravelerPanFile = async () => {
      const formData = new FormData();

      for (const file of travelerPanFile) {
        formData.append('images[]', file);
      }

      try {
        const response = await axios.post("https://admin.trawelmart.com/admin/public/index.php/api/uploadTravelerPanFile", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.status) {
          console.log(response.data.files);
          setTravelerSelectedPanFile(extractFileName(response.data.files));
          setTravelerPanFile([]);
          const realElement = document.getElementById("traveler_pan_file_holder_real");
          const travelerPanImageElement = document.getElementById("traveler_pan_image_file") as HTMLImageElement;
          const hiddenElement = document.getElementById("traveler_pan_file_holder_hidden");

          if (realElement) {
            realElement.style.display = "none";
          }

          if (travelerPanImageElement) {
            travelerPanImageElement.src = "https://admin.trawelmart.com/admin/public/" + response.data.files;
          }

          if (hiddenElement) {
            hiddenElement.style.display = "block";
          }
        }
        else {
          toastr.error("Some error occurred");
        }
      }
      catch (error) {
        console.error("Upload failed ", error);
      }
    }

    if (travelerPanFile.length > 0) {
      uploadTravelerPanFile();
    }
  }, [travelerPanFile]);

  function deletePassportFile() {
    setPassportFile([]);
    setSelectedPassportFile('');
    const realElement = document.getElementById("passport_file_holder_real");
    const passportImageElement = document.getElementById("passport_image_file") as HTMLImageElement;
    const hiddenElement = document.getElementById("passport_file_holder_hidden");
    if (realElement) {
      realElement.style.display = "block";
    }
    if (passportImageElement) {
      passportImageElement.src = "";
    }
    if (hiddenElement) {
      hiddenElement.style.display = "none";
    }
  }

  function deleteAadharFile() {
    setAadharFile([]);
    setSelectedAadharFile('');
    const realElement = document.getElementById("aadhar_file_holder_real");
    const aadharImageElement = document.getElementById("aadhar_image_file") as HTMLImageElement;
    const hiddenElement = document.getElementById("aadhar_file_holder_hidden");
    if (realElement) {
      realElement.style.display = "block";
    }
    if (aadharImageElement) {
      aadharImageElement.src = "";
    }
    if (hiddenElement) {
      hiddenElement.style.display = "none";
    }
  }

  function deletePanFile() {
    setPanFile([]);
    setSelectedPanFile('');
    const realElement = document.getElementById("pan_file_holder_real");
    const panImageElement = document.getElementById("pan_image_file") as HTMLImageElement;
    const hiddenElement = document.getElementById("pan_file_holder_hidden");
    if (realElement) {
      realElement.style.display = "block";
    }
    if (panImageElement) {
      panImageElement.src = "";
    }
    if (hiddenElement) {
      hiddenElement.style.display = "none";
    }
  }

  function deleteTravelerPassportFile() {
    setTravelerPassportFile([]);
    setTravelerSelectedPassportFile('');
    const realElement = document.getElementById("traveler_passport_file_holder_real");
    const travelerPassportImageElement = document.getElementById("traveler_passport_image_file") as HTMLImageElement;
    const hiddenElement = document.getElementById("traveler_passport_file_holder_hidden");
    if (realElement) {
      realElement.style.display = "block";
    }
    if (travelerPassportImageElement) {
      travelerPassportImageElement.src = "";
    }
    if (hiddenElement) {
      hiddenElement.style.display = "none";
    }
  }

  function deleteTravelerAadharFile() {
    setTravelerAadharFile([]);
    setTravelerSelectedAadharFile('');
    const realElement = document.getElementById("traveler_aadhar_file_holder_real");
    const travelerAadharImageElement = document.getElementById("traveler_aadhar_image_file") as HTMLImageElement;
    const hiddenElement = document.getElementById("traveler_aadhar_file_holder_hidden");
    if (realElement) {
      realElement.style.display = "block";
    }
    if (travelerAadharImageElement) {
      travelerAadharImageElement.src = "";
    }
    if (hiddenElement) {
      hiddenElement.style.display = "none";
    }
  }

  function deleteTravelerPanFile() {
    setTravelerPanFile([]);
    setTravelerSelectedPanFile('');
    const realElement = document.getElementById("traveler_pan_file_holder_real");
    const travelerPanImageElement = document.getElementById("traveler_pan_image_file") as HTMLImageElement;
    const hiddenElement = document.getElementById("traveler_pan_file_holder_hidden");
    if (realElement) {
      realElement.style.display = "block";
    }
    if (travelerPanImageElement) {
      travelerPanImageElement.src = "";
    }
    if (hiddenElement) {
      hiddenElement.style.display = "none";
    }
  }

  const TourItem: React.FC<{ tour: any }> = ({ tour }) => {
    const [showContent, setShowContent] = useState(false);

    const toggleContent = () => {
      setShowContent(!showContent);
    };

    return (
      <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
        <button onClick={toggleContent} className="w-full">
          {activeTab === 1 &&

            <div className="border border-neutral-300 rounded-md shadow-md p-4 xl:pt-6 xl:mt-0 lg:mt-0 mt-4 xl:text-xl">

              <div className="w-28 border-orange-500 rounded border uppercase xl:text-xs md:text-xs text-xs font1 p-1 text-orange-500 font-medium">
                {tour.package.tour_city} Tour
              </div>
              <a href={'https://trawelmart.com/' + tour.package.slug}>
                <h5 className="text-left font1 font-bold xl:text-base lg:text-base md:text-base text-base pt-2 md:pt-2 xl:pt-2">
                  {tour.package.tour_title}
                </h5>
              </a>

              <div className="text-left grid xl:grid-cols-2 xl:gap-12 lg:grid-cols-1 lg:gap-6 md:grid-cols-1 xl:mt-4 mt-2 grid-cols-1 items-center ">
                <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 grid-cols-4 gap-2">
                  <div className="border-r p-1 ">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs mb-1">
                      Booked on
                    </h4>
                    <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                      {new Date(tour.checkout.created_at).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric", })}
                    </div>
                  </div>
                  <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                      Start Date
                    </h4>
                    <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                      {`${moment(tour.checkout.check_in_date).format('MMM D')}`}
                    </div>
                  </div>
                  <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                      End Date
                    </h4>
                    <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                      {`${moment(tour.checkout.check_out_date).format('MMM D')}`}
                    </div>
                  </div>
                  <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                      Status
                    </h4>
                    <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                      {`${tour.checkout.status}`}
                    </div>
                  </div>
                  {/* <div className="p-1 ml-1">
                  <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                    Tour Code
                  </h4>
                  <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs  text-xs">
                    MEMETRAWEL2023
                  </div>
                </div> */}
                </div>
                <div className="flex xl:mt-0 lg:mt-0 md:mt-5 mt-4">
                  <div className="xl:w-3/12">
                  </div>
                  <div className="flex xl:w-9/12 md:w-7/12 w-full">
                    <button className="w-6/12 bg-sky-600 text-white text-xs px-2 py-2 rounded">
                      <a href={'https://admin.trawelmart.com/admin/public/index.php/api/downloadInvoice/' + tour.checkout.id} target="_blank">
                        Download Invoice
                      </a>
                    </button>
                    <button className="w-6/12 bg-orange-400 text-white text-xs px-2 py-2  ml-2 rounded">
                      Booking Details
                    </button>
                  </div>
                </div>
              </div>

            </div>}
        </button>

        {showContent && (
          <div className="">
            <button onClick={reset}>
              {/* <h3 className="xl:text-white lg:text-white md:text-sky-600 font1 xl:text-base pb-2">
                  <i className="las la-arrow-alt-circle-left pr-1"></i>Back
                </h3> */}
            </button>
            <p>
              <div className="xl:flex lg:flex md:flex border border-neutral-300 rounded-md shadow-md p-4 xl:pt-6 xl:text-xl bg-white xl:w-12/12 lg:w-full">
                <div className="xl:w-8/12 lg:w-8/12 md:w-8/12 w-12/12  pr-6">
                  <div className="w-28 border-orange-500 rounded border uppercase xl:text-xs text-xs font1 p-1 text-orange-500 font-medium xl:mb-0 lg:mb-0 md:mb-0 mb-2">
                    {tour.package.tour_city} Tour
                  </div>
                  <a href={'https://trawelmart.com/' + tour.package.slug}>
                    <h5 className="text-left font1 font-bold xl:text-base text-base lg:text-base md:text-base md:text-xl md:pt-2 xl:pt-2">
                      {tour.package.tour_title}
                    </h5>
                  </a>
                  <p className="text-sm font pt-2">
                    <strong>{parseInt(tour.checkout.adult, 10) + parseInt(tour.checkout.children, 10) + parseInt(tour.checkout.infant, 10)}</strong> Guest  <span className="pl-3"><strong>{tour.package.tour_days}</strong> Days</span>
                  </p>

                  <div className="grid xl:grid-cols-4 lg:grid-cols-4  md:grid-cols-4 grid-cols-4 xl:pt-8 lg:pt-8 md:pt-8 pt-4">
                    <div className="p-1 ">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm text-xs lg:text-xs md:text-xs mb-1">
                        Booked on
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {new Date(tour.checkout.created_at).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric", })}
                      </div>
                    </div>
                    <div className=" p-1 ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Start Date
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {`${moment(tour.checkout.check_in_date).format('MMM D')}`}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        End Date
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {`${moment(tour.checkout.check_out_date).format('MMM D')}`}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Status
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {`${tour.checkout.status}`}
                      </div>
                    </div>
                  </div>

                  <div className="border-b py-1"></div>
                  <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-3 pt-4">
                    <div className="p-1 ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Guests
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {tour.checkout.adult} Adults | {tour.checkout.children} Childrens |{' '}{tour.checkout.infant} Infants
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Rooms
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {tour.checkout.total_rooms}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Departure
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {tour.checkout.starting_place}
                      </div>
                    </div>
                  </div>
                  <div className="border-b py-1"></div>

                  <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-3 pt-4">
                    <div className="p-1 ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Booking Person
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {userName}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Form Number
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        TWT000{tour.checkout.id}
                      </div>
                    </div>
                  </div>

                  <div className="border-b py-1"></div>

                  <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-2 pt-4">

                  </div>
                </div>
                <div className="border shadow rounded-md p-4 xl:w-4/12 lg:w-4/12 md:w-4/12 w-12/12 mt-4 xl:mt-0 lg:mt-0 md:mt-0 ">
                  <h1 className="text-sky-600 font text-base mb-1">
                    Payment
                  </h1>

                  <p className="xl:mt-6 text-sm font-semibold font mb-1">
                    Total Amount
                    <p className=""> ₹{tour.checkout.total_amount} </p>
                  </p>
                  <p className="xl:mt-2 text-sm font-medium font mb-1">
                    Amount Paid
                    <p className=""> ₹{tour.checkout.paid_amount ? tour.checkout.paid_amount : '0'} </p>
                  </p>
                  <p className="xl:mt-2 text-sm font-medium font flex justify-between">
                    Balance Amount
                    <p className=""> ₹{tour.checkout.paid_amount ? tour.checkout.total_amount - tour.checkout.paid_amount : tour.checkout.total_amount} </p>
                  </p>

                  <p
                    className='px-4 py-2 text-black rounded text-base font-semibold'
                    onClick={() => iropenModal(tour.package.id)}
                  >
                    View Price Breakup
                  </p>
                </div>
              </div>
              {/* <section id="includ" className=" lg:block cursor-pointer">
                  {accordionData3.map((item, index) => (
                    <Accordion key={index} title={item.title} content={item.content} />
                  ))}
                </section> */}
            </p>
          </div>
        )}
        <Transition
          appear
          show={selectedItemId == tour.package.id && selectedItemId != null}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="relative z-10"
            onClose={ircloseModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0  bg-opacity-25" />
            </Transition.Child>
            <div className="font fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle transition-all">
                    <div className="text-right mt-2 mr-2">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={ircloseModal}
                      >
                        X
                      </button>
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="font p-4 bg-[#fbf7fb] lg:-mt-12 md:-mt-9 -mt-12 lg:pt-6 text-base font-regular text-left leading-6 text-sky-600"
                    >
                      Trawel Mart's Detailed Tour Price
                      <p className="xl:text-xs font font-regular text-gray-500">
                        Prices & discounts are Per
                        Person for Indian Nationals
                        only.
                      </p>
                    </Dialog.Title>
                    <div className="p-4">
                      <table
                        key="table"
                        className="table"
                      >
                        <table
                          className="rounded-2xl"
                          style={{ width: "480px" }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "230px",
                                }}
                              >
                                Premium Packages
                              </th>
                              <th
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  backgroundColor:
                                    "#f9f9f9",
                                  width: "230px",
                                }}
                              >
                                Adult
                              </td>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  backgroundColor:
                                    "#f9f9f9",
                                }}
                              >
                                {" "}
                                {pricingData
                                  ? formatPrice(
                                    (
                                      pricingData as any
                                    ).adult
                                  )
                                  : "0"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  width: "210px",
                                }}
                              >
                                Child With Bed
                              </td>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                {pricingData
                                  ? formatPrice(
                                    (
                                      pricingData as any
                                    ).child
                                  )
                                  : "0"}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  backgroundColor:
                                    "#f9f9f9",
                                  width: "230px",
                                }}
                              >
                                Child Without Bed
                              </td>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  backgroundColor:
                                    "#f9f9f9",
                                }}
                              >
                                {" "}
                                {pricingData
                                  ? formatPrice(
                                    (
                                      pricingData as any
                                    ).infant
                                  )
                                  : "0"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </table>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const TourItem1: React.FC<{ tour: any }> = ({ tour }) => {
    const [showContent, setShowContent] = useState(false);

    const toggleContent = () => {
      setShowContent(!showContent);
    };

    return (
      <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
        <button onClick={toggleContent} className="w-full">
          {activeTab === 2 &&

            <div className="border border-neutral-300 rounded-md shadow-md p-4 xl:pt-6 xl:text-xl">

              <div className="w-28 border-orange-500 rounded border uppercase xl:text-xs md:text-xs text-xs font1 p-1 text-orange-500 font-medium">
                {tour.package.tour_city} Tour
              </div>
              <a href={'https://trawelmart.com/' + tour.package.slug}>
                <h5 className="text-left font1 font-bold xl:text-base md:text-xl text-base xl:pt-2 md:pt-3 pt-2">
                  {tour.package.tour_title}
                </h5>
              </a>

              <div className="text-left grid xl:grid-cols-2 xl:mt-4 md:mt-4 mt-2 items-center xl:gap-12">
                <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 grid-cols-4">
                  <div className="border-r p-1 ">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                      Booked on
                    </h4>
                    <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                      {new Date(tour.checkout.created_at).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric", })}
                    </div>
                  </div>
                  <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                      Start Date
                    </h4>
                    <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                      {`${moment(tour.checkout.check_in_date).format('MMM D')}`}
                    </div>
                  </div>
                  <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                      End Date
                    </h4>
                    <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                      {`${moment(tour.checkout.check_out_date).format('MMM D')}`}
                    </div>
                  </div>
                  <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                      Status
                    </h4>
                    <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                      {`${tour.checkout.status}`}
                    </div>
                  </div>
                  {/* <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1 ">
                  <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                    Tour Code
                  </h4>
                  <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                    DUBAITRAWEL2022
                  </div>
                </div> */}
                </div>
                <div className="flex" >
                  <div className="xl:w-3/12 ">
                  </div>
                  <div className="flex xl:w-9/12 md:w-7/12  w-12/12">
                    <button className="w-6/12 bg-sky-600 text-white text-xs px-2 py-2 rounded">
                      <a href={'https://admin.trawelmart.com/admin/public/index.php/api/downloadInvoice/' + tour.checkout.id} target="_blank">
                        Download Invoice
                      </a>
                    </button>
                    <button className="xl:w-6/12 md:w-6/12 lg:w-6/12 w-12/12 bg-orange-400 text-white text-xs px-2 py-2  ml-2 rounded xl:mt-0 lg:mt-0 mt-4">
                      Booking Details
                    </button>

                  </div>
                </div>
              </div>
            </div>}
        </button>

        {showContent && (
          <div className="">
            <button onClick={reset}>
              {/* <h3 className="xl:text-white lg:text-white md:text-sky-600 font1 xl:text-base pb-2">
                  <i className="las la-arrow-alt-circle-left pr-1"></i>Back
                </h3> */}


            </button>
            <p>
              <div className="flex border border-neutral-300 rounded-md shadow-md p-4 xl:pt-6 xl:text-xl bg-white xl:w-12/12 lg:w-full">
                <div className="w-8/12 pr-6">
                  <div className="w-28 border-orange-500 rounded border uppercase xl:text-xs text-xs font1 p-1 text-orange-500 font-medium xl:mb-0 lg:mb-0 md:mb-0 mb-2">
                    {tour.package.tour_city} Tour
                  </div>
                  <a href={'https://trawelmart.com/' + tour.package.slug}>
                    <h5 className="text-left font1 font-bold xl:text-base lg:text-xl md:text-xl md:pt-2 xl:pt-2">
                      {tour.package.tour_title}
                    </h5>
                  </a>
                  <p className="text-sm font pt-2">
                    <strong>{parseInt(tour.checkout.adult, 10) + parseInt(tour.checkout.children, 10) + parseInt(tour.checkout.infant, 10)}</strong> Guest  <span className="pl-3"><strong>{tour.package.tour_days}</strong> Days</span>
                  </p>

                  <div className="grid xl:grid-cols-4 lg:grid-cols-4  md:grid-cols-4 grid-cols-4 xl:pt-8 lg:pt-8 md:pt-8 pt-4">
                    <div className="p-1 ">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Booked on
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        {new Date(tour.checkout.created_at).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric", })}
                      </div>
                    </div>
                    <div className=" p-1 ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Start Date
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        {`${moment(tour.checkout.check_in_date).format('MMM D')}`}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        End Date
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        {`${moment(tour.checkout.check_out_date).format('MMM D')}`}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Status
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        {`${tour.checkout.status}`}
                      </div>
                    </div>
                  </div>

                  <div className="border-b py-1"></div>
                  <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-3 pt-4">
                    <div className="p-1 ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Guests
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {tour.checkout.adult} Adults | {tour.checkout.children} Childrens |{' '}{tour.checkout.infant} Infants
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Rooms
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {tour.checkout.total_rooms}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Departure
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {tour.checkout.starting_place}
                      </div>
                    </div>
                  </div>
                  <div className="border-b py-1"></div>

                  <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-2 pt-4">
                    <div className="p-1 ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Booking Person
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        {userName}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Form Number
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        TWT000{tour.checkout.id}
                      </div>
                    </div>
                  </div>

                  <div className="border-b py-1"></div>

                  <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-2 pt-4">
                  </div>
                </div>
                <div className="border shadow rounded-md p-4 w-4/12 ">
                  <h1 className="text-sky-600 font text-base">
                    Payment
                  </h1>

                  <p className="xl:mt-6 text-sm font-semibold font mb-1">
                    Total Amount
                    <p className=""> ₹{tour.checkout.total_amount} </p>
                  </p>
                  <p className="xl:mt-2 text-sm font-medium font mb-1">
                    Amount Paid
                    <p className=""> ₹{tour.checkout.paid_amount ? tour.checkout.paid_amount : '0'} </p>
                  </p>
                  <p className="xl:mt-2 text-sm font-medium font flex justify-between">
                    Balance Amount
                    <p className=""> ₹{tour.checkout.paid_amount ? tour.checkout.total_amount - tour.checkout.paid_amount : tour.checkout.total_amount} </p>
                  </p>

                  <p
                    className='px-4 py-2 text-black rounded text-base font-semibold'
                    onClick={() => iropenModal(tour.package.id)}
                  >
                    View Price Breakup
                  </p>
                </div>
              </div>
              {/* <section id="includ" className=" lg:block cursor-pointer">
                  {accordionData3.map((item, index) => (
                    <Accordion key={index} title={item.title} content={item.content} />
                  ))}
                </section> */}
            </p>
          </div>
        )}
        <Transition
          appear
          show={selectedItemId == tour.package.id && selectedItemId != null}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="relative z-10"
            onClose={ircloseModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0  bg-opacity-25" />
            </Transition.Child>
            <div className="font fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle transition-all">
                    <div className="text-right mt-2 mr-2">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={ircloseModal}
                      >
                        X
                      </button>
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="font p-4 bg-[#fbf7fb] lg:-mt-12 md:-mt-9 -mt-12 lg:pt-6 text-base font-regular text-left leading-6 text-sky-600"
                    >
                      Trawel Mart's Detailed Tour Price
                      <p className="xl:text-xs font font-regular text-gray-500">
                        Prices & discounts are Per
                        Person for Indian Nationals
                        only.
                      </p>
                    </Dialog.Title>
                    <div className="p-4">
                      <table
                        key="table"
                        className="table"
                      >
                        <table
                          className="rounded-2xl"
                          style={{ width: "480px" }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "230px",
                                }}
                              >
                                Premium Packages
                              </th>
                              <th
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  backgroundColor:
                                    "#f9f9f9",
                                  width: "230px",
                                }}
                              >
                                Adult
                              </td>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  backgroundColor:
                                    "#f9f9f9",
                                }}
                              >
                                {" "}
                                {pricingData
                                  ? formatPrice(
                                    (
                                      pricingData as any
                                    ).adult
                                  )
                                  : "0"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  width: "210px",
                                }}
                              >
                                Child With Bed
                              </td>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                {pricingData
                                  ? formatPrice(
                                    (
                                      pricingData as any
                                    ).child
                                  )
                                  : "0"}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  backgroundColor:
                                    "#f9f9f9",
                                  width: "230px",
                                }}
                              >
                                Child Without Bed
                              </td>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  backgroundColor:
                                    "#f9f9f9",
                                }}
                              >
                                {" "}
                                {pricingData
                                  ? formatPrice(
                                    (
                                      pricingData as any
                                    ).infant
                                  )
                                  : "0"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </table>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const TourItem2: React.FC<{ tour: any }> = ({ tour }) => {
    const [showContent, setShowContent] = useState(false);

    const toggleContent = () => {
      setShowContent(!showContent);
    };

    return (
      <div style={{ display: activeTab === 3 ? 'block' : 'none' }}>
        <button onClick={toggleContent} className="w-full">
          {activeTab === 3 &&

            <div className="border border-neutral-300 rounded-md shadow-md p-4 xl:pt-6 xl:text-xl">

              <div className="w-28 border-orange-500 rounded border uppercase xl:text-xs md:text-xs text-xs font1 p-1 text-orange-500 font-medium">
                {tour.package.tour_city} Tour
              </div>
              <a href={'https://trawelmart.com/' + tour.package.slug}>
                <h5 className="text-left font1 font-bold xl:text-base md:text-xl text-base xl:pt-2 md:pt-3 pt-2">
                  {tour.package.tour_title}
                </h5>
              </a>

              <div className="text-left grid xl:grid-cols-2 xl:mt-4 md:mt-4 mt-2 items-center xl:gap-12">
                <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 grid-cols-4">
                  <div className="border-r p-1 ">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                      Booked on
                    </h4>
                    <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                      -
                    </div>
                  </div>
                  <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                      Start Date
                    </h4>
                    <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                      {`${moment(tour.checkout.check_in_date).format('MMM D')}`}
                    </div>
                  </div>
                  <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                      End Date
                    </h4>
                    <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                      {`${moment(tour.checkout.check_out_date).format('MMM D')}`}
                    </div>
                  </div>
                  <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                    <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                      Status
                    </h4>
                    <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                      {`${tour.checkout.status}`}
                    </div>
                  </div>
                  {/* <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1 ">
                  <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                    Tour Code
                  </h4>
                  <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                    DUBAITRAWEL2022
                  </div>
                </div> */}
                </div>
                <div className="flex" >
                  <div className="xl:w-3/12 ">
                  </div>
                  <div className="flex xl:w-9/12 md:w-7/12  w-12/12">
                    <button className="w-6/12 bg-sky-600 text-white text-xs px-2 py-2 rounded">
                      <a href={'https://admin.trawelmart.com/admin/public/index.php/api/downloadInvoice/' + tour.checkout.id} target="_blank">
                        Download Invoice
                      </a>
                    </button>
                    <button className="xl:w-6/12 md:w-6/12 lg:w-6/12 w-12/12 bg-orange-400 text-white text-xs px-2 py-2  ml-2 rounded xl:mt-0 lg:mt-0 mt-4">
                      Booking Details
                    </button>

                  </div>
                </div>
              </div>
            </div>}
        </button>

        {showContent && (
          <div className="">
            <button onClick={reset}>
              {/* <h3 className="xl:text-white lg:text-white md:text-sky-600 font1 xl:text-base pb-2">
                  <i className="las la-arrow-alt-circle-left pr-1"></i>Back
                </h3> */}


            </button>
            <p>
              <div className="flex border border-neutral-300 rounded-md shadow-md p-4 xl:pt-6 xl:text-xl bg-white xl:w-12/12 lg:w-full">
                <div className="w-8/12 pr-6">
                  <div className="w-28 border-orange-500 rounded border uppercase xl:text-xs text-xs font1 p-1 text-orange-500 font-medium xl:mb-0 lg:mb-0 md:mb-0 mb-2">
                    {tour.package.tour_city} Tour
                  </div>
                  <a href={'https://trawelmart.com/' + tour.package.slug}>
                    <h5 className="text-left font1 font-bold xl:text-base lg:text-xl md:text-xl md:pt-2 xl:pt-2">
                      {tour.package.tour_title}
                    </h5>
                  </a>
                  <p className="text-sm font pt-2">
                    <strong>{parseInt(tour.checkout.adult, 10) + parseInt(tour.checkout.children, 10) + parseInt(tour.checkout.infant, 10)}</strong> Guest  <span className="pl-3"><strong>{tour.package.tour_days}</strong> Days</span>
                  </p>

                  <div className="grid xl:grid-cols-4 lg:grid-cols-4  md:grid-cols-4 grid-cols-4 xl:pt-8 lg:pt-8 md:pt-8 pt-4">
                    <div className="p-1 ">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Booked on
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        -
                      </div>
                    </div>
                    <div className=" p-1 ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Start Date
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        {`${moment(tour.checkout.check_in_date).format('MMM D')}`}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        End Date
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        {`${moment(tour.checkout.check_out_date).format('MMM D')}`}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Status
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        {`${tour.checkout.status}`}
                      </div>
                    </div>
                  </div>

                  <div className="border-b py-1"></div>
                  <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-3 pt-4">
                    <div className="p-1 ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Guests
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {tour.checkout.adult} Adults | {tour.checkout.children} Childrens |{' '}{tour.checkout.infant} Infants
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Rooms
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {tour.checkout.total_rooms}
                      </div>
                    </div>
                    <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                        Departure
                      </h4>
                      <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                        {tour.checkout.starting_place}
                      </div>
                    </div>
                  </div>
                  <div className="border-b py-1"></div>

                  <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-2 pt-4">
                    <div className="p-1 ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Booking Person
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        {userName}
                      </div>
                    </div>
                    {/* <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Form Number
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        TWT000{tour.checkout.id}
                      </div>
                    </div> */}
                  </div>

                  <div className="border-b py-1"></div>

                  <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-2 pt-4">
                  </div>
                </div>
                <div className="border shadow rounded-md p-4 w-4/12 ">
                  <h1 className="text-sky-600 font text-base">
                    Payment
                  </h1>

                  <p className="xl:mt-6 text-sm font-semibold font mb-1">
                    Total Amount
                    <p className=""> ₹{tour.checkout.total_amount} </p>
                  </p>
                  <p className="xl:mt-2 text-sm font-medium font mb-1">
                    Amount Paid
                    <p className=""> ₹{tour.checkout.paid_amount ? tour.checkout.paid_amount : '0'} </p>
                  </p>
                  <p className="xl:mt-2 text-sm font-medium font flex justify-between">
                    Balance Amount
                    <p className=""> ₹{tour.checkout.paid_amount ? tour.checkout.total_amount - tour.checkout.paid_amount : tour.checkout.total_amount} </p>
                  </p>

                  <p
                    className='px-4 py-2 text-black rounded text-base font-semibold'
                    onClick={() => iropenModal(tour.package.id)}
                  >
                    View Price Breakup
                  </p>
                </div>
              </div>
              {/* <section id="includ" className=" lg:block cursor-pointer">
                  {accordionData3.map((item, index) => (
                    <Accordion key={index} title={item.title} content={item.content} />
                  ))}
                </section> */}
            </p>
          </div>
        )}
        <Transition
          appear
          show={selectedItemId == tour.package.id && selectedItemId != null}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="relative z-10"
            onClose={ircloseModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0  bg-opacity-25" />
            </Transition.Child>
            <div className="font fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle transition-all">
                    <div className="text-right mt-2 mr-2">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={ircloseModal}
                      >
                        X
                      </button>
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="font p-4 bg-[#fbf7fb] lg:-mt-12 md:-mt-9 -mt-12 lg:pt-6 text-base font-regular text-left leading-6 text-sky-600"
                    >
                      Trawel Mart's Detailed Tour Price
                      <p className="xl:text-xs font font-regular text-gray-500">
                        Prices & discounts are Per
                        Person for Indian Nationals
                        only.
                      </p>
                    </Dialog.Title>
                    <div className="p-4">
                      <table
                        key="table"
                        className="table"
                      >
                        <table
                          className="rounded-2xl"
                          style={{ width: "480px" }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  textAlign: "left",
                                  width: "230px",
                                }}
                              >
                                Premium Packages
                              </th>
                              <th
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  backgroundColor:
                                    "#f9f9f9",
                                  width: "230px",
                                }}
                              >
                                Adult
                              </td>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  backgroundColor:
                                    "#f9f9f9",
                                }}
                              >
                                {" "}
                                {pricingData
                                  ? formatPrice(
                                    (
                                      pricingData as any
                                    ).adult
                                  )
                                  : "0"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  width: "210px",
                                }}
                              >
                                Child With Bed
                              </td>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                {pricingData
                                  ? formatPrice(
                                    (
                                      pricingData as any
                                    ).child
                                  )
                                  : "0"}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  backgroundColor:
                                    "#f9f9f9",
                                  width: "230px",
                                }}
                              >
                                Child Without Bed
                              </td>
                              <td
                                style={{
                                  border:
                                    "1px solid #d9d7d7",
                                  padding: "10px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  backgroundColor:
                                    "#f9f9f9",
                                }}
                              >
                                {" "}
                                {pricingData
                                  ? formatPrice(
                                    (
                                      pricingData as any
                                    ).infant
                                  )
                                  : "0"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </table>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="bg-white  w-80 md:w-full flex flex-col items-center text-left sm:rounded-2xl rounded-lg sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-4 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28 -mt-20"
          userName={userName}
        />
        {/* ---- */}
        <div className="space-y-1 text-left w-full xl:pl-5">
          <h2 className="font1 text-2xl text-sky-600 font-bold text-left pl-4 xl:pl-0 lg:pl-0 md:pl-0">{userName}</h2>
          <h3 className="font text-base font-regular text-left pl-4 xl:pl-0 lg:pl-0 md:pl-0">{userEmail}</h3>
          <h4 className="font text-base font-regular text-left pl-4 xl:pl-0 lg:pl-0 md:pl-0">{userMobileNumber}</h4>
          {/* <StartRating className="!text-base" /> */}
        </div>

        <div className="text-left w-full px-30 border-t border-600">
          <div className="cursor-pointer mt-3 px-4 py-2 rounded-md  hover:bg-[#f4eeff] focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-opacity-75 ">
            <button onClick={showAccount} className={`${isAccountVisible ? 'text-sky-600' : ''}`}>
              <div className="flex items-center ">
                <i className="las la-user-circle text-xl"></i>
                <button
                  type="button"
                  className="rounded-md px-2 py-1 text-base font-semibold font1"
                >
                  My Account
                </button>
              </div>
            </button>
          </div>

          <div className="cursor-pointer mt-1 px-4 py-1 rounded-md  hover:bg-[#f4eeff] focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-opacity-75 ">
            <button onClick={showTour} className={`${isTourVisible ? 'text-sky-600' : ''}`}>
              <div className="flex items-center">
                <i className="las la-calendar-check text-xl"></i>
                <button
                  type="button"
                  className="rounded-md px-2 py-2 text-base font-semibold font1"
                >
                  Tour Packages
                </button>
              </div>
            </button>
          </div>
          {/* <div className="cursor-pointer mt-1 px-4 py-1 rounded-md  hover:bg-[#f4eeff] focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-opacity-75 ">
            <button onClick={showCart} className={`${isCartVisible ? 'text-sky-600' : ''}`}>
              <div className="flex items-center">
                <i className="las la-shopping-cart text-xl"></i>
                <button
                  type="button"
                  className="rounded-md px-2 py-2 text-base font-semibold  font1"
                >
                  My Holiday Cart
                </button>
              </div>
            </button>
          </div>

          <div className="cursor-pointer mt-1 px-4 py-1 rounded-md  hover:bg-[#f4eeff] focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-opacity-75 ">
            <button onClick={showWhislist} className={`${isWishlistVisible ? 'text-sky-600' : ''}`}>
              <div className="flex items-center">
                <i className="las la-bookmark text-xl"></i>
                <button
                  type="button"
                  className="rounded-md px-2 py-2 text-base font-semibold  font1"
                >
                  Wishlist
                </button>
              </div>
            </button>
          </div> */}

          <div className="flex items-center mt-1 px-4 py-1 rounded-md  hover:bg-[#f4eeff] focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-opacity-75 ">
            <i className="las la-sign-out-alt text-xl"></i>
            <button
              type="button"
              className="rounded-md px-2 py-2 text-base font-semibold  font1"
              onClick={logout}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="font w-full ">
        <div className="">
          {showBox && (
            <div className="grid xl:grid-cols-3 xl:gap-0 gap-2 lg:grid-cols-3 lg:gap-0 md:grid-cols-3 md:gap-0 grid-cols-3 gap-0 bg-white rounded-lg py-4 shadow-xl px-6">
              <button
                className={activeTab === 1 ? 'text-sky-600 xl:mx-4 lg:mx-4 md:mx-4 xl:text-base  lg:text-base md:text-base text-xs font1' : ' xl:text-base  border-sky-600 border-r lg:text-base md:text-base text-xs  xl:py-0 py-2 font1'}
                onClick={() => handleTabClick(1)}
              >
                <i className="las la-globe text-2xl"></i><br></br>
                <p className="">
                  Upcoming Tours ({upcomingTours.length})
                </p>
              </button>
              <button
                className={activeTab === 2 ? ' text-sky-600 xl:mx-4 lg:mx-4 md:mx-4 xl:text-base lg:text-base md:text-base text-xs  font1 ' : 'pl-1 xl:text-base  border-sky-600 border-l lg:text-base md:text-base text-xs  xl:py-0 py-2 font1'}
                onClick={() => handleTabClick(2)}
              >
                <i className="las la-suitcase text-2xl"></i><br></br>
                <p className="">
                  Completed Tours ({completedTours.length})
                </p>
              </button>
              <button
                className={activeTab === 3 ? ' text-sky-600 xl:mx-4 lg:mx-4 md:mx-4 xl:text-base lg:text-base md:text-base text-xs  border-sky-600 border-l font1' : 'pl-1  border-sky-600 border-l xl:text-base lg:text-base md:text-base text-xs  xl:py-0 py-2 font1'}
                onClick={() => handleTabClick(3)}
              >
                <i className="las la-suitcase text-2xl"></i><br></br>
                <p className="">
                  {/* Cancelled Tours ({localStorage.getItem("packageId") !== null ? 1 : 0} + {cancelledTours.length}) */}
                  Cancelled Tours ({cancelledTours.length})
                </p>
              </button>
            </div>
          )}
        </div>

        <div className="xl:mt-14 md:mt-8">
          {showButton && upcomingTours.map((tour, index) => (
            <TourItem key={index} tour={tour} />
          ))}

          {showButton1 && completedTours.map((tour, index) => (
            <TourItem1 key={index} tour={tour} />
          ))}

          {showButton2 && cancelledTours.map((tour, index) => (
            <TourItem2 key={index} tour={tour} />
          ))}

          {showButton3 && cartTour && <button onClick={toggleContent2} className="w-full">
            {activeTab === 3 &&

              <div className="border border-neutral-300 rounded-md shadow-md p-4 xl:pt-6 xl:text-xl">

                <div className="w-28 border-orange-500 rounded border uppercase xl:text-xs md:text-xs text-xs font1 p-1 text-orange-500 font-medium">
                  {(cartTour as any).tour_city} Tour
                </div>
                <a href={'https://trawelmart.com/' + (cartTour as any).slug}>
                  <h5 className="text-left font1 font-bold xl:text-base md:text-xl text-base xl:pt-2 md:pt-3 pt-2">
                    {(cartTour as any).tour_title}
                  </h5>
                </a>

                <div className="text-left grid xl:grid-cols-2 xl:mt-4 md:mt-4 mt-2 items-center xl:gap-12">
                  <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2">
                    <div className="border-r p-1 ">
                      <h4 className="font1 font-medium text-neutral-500  xl:text-sm lg:text-xs md:text-xs text-xs">
                        Booked on
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        -
                      </div>
                    </div>
                    <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Travelling Date
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        {`${new Date(localStorage.getItem("checkinDate") || '').toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} - ${new Date(localStorage.getItem("checkoutDate") || "").toLocaleDateString("en-US", { day: 'numeric', month: 'short', year: '2-digit' })}`}
                      </div>
                    </div>
                    {/* <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1 ">
                      <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                        Tour Code
                      </h4>
                      <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                        DUBAITRAWEL2022
                      </div>
                    </div> */}
                  </div>
                  <div className="flex" >
                    <div className="xl:w-3/12 ">
                    </div>
                    <div className="flex xl:w-9/12 md:w-7/12  w-12/12">
                      <button className="xl:w-6/12 ">

                      </button>
                      <button className="xl:w-6/12 md:w-6/12 lg:w-6/12 w-12/12 bg-orange-400 text-white text-xs px-2 py-2  ml-2 rounded xl:mt-0 lg:mt-0 mt-4">
                        Booking Details
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            }
          </button>}
          {showContent3 && cartTour && (
            <div className="">
              <button onClick={reset}>
                <h3 className="xl:text-black lg:text-black md:text-sky-600 font1 xl:text-base pb-2">
                  <i className="las la-arrow-alt-circle-left pr-1"></i>Back
                </h3>

              </button>
              <p>
                <div className="flex border border-neutral-300 rounded-md shadow-md p-4 xl:pt-6 xl:text-xl bg-white xl:w-12/12 lg:w-full">
                  <div className="w-8/12 pr-6">
                    <div className="w-28 border-orange-500 rounded border uppercase xl:text-xs text-xs font1 p-1 text-orange-500 font-medium xl:mb-0 lg:mb-0 md:mb-0 mb-2">
                      {(cartTour as any).tour_city} Tour
                    </div>
                    <a href={"https://trawelmart.com/" + (cartTour as any).slug}>
                      <h5 className="text-left font1 font-bold xl:text-base lg:text-xl md:text-xl md:pt-2 xl:pt-2">
                        {(cartTour as any).tour_title}
                      </h5>
                    </a>
                    <p className="text-sm font pt-2">
                      <strong>{parseInt(localStorage.getItem("adultCount") || '0', 10) + parseInt(localStorage.getItem("childrenCount") || "0", 10) + parseInt(localStorage.getItem("infantCount") || '0', 10)}</strong> Guest  <span className="pl-3"><strong>{(cartTour as any).tour_days}</strong> Days</span>
                    </p>

                    <div className="grid xl:grid-cols-4 lg:grid-cols-4  md:grid-cols-4 grid-cols-4 xl:pt-8 lg:pt-8 md:pt-8 pt-4">
                      <div className="p-1 ">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                          Booked on
                        </h4>
                        <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                          -
                        </div>
                      </div>
                      <div className=" p-1 ml-1">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                          Start Date
                        </h4>
                        <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                          {`${localStorage.getItem("checkinDate")}`}
                        </div>
                      </div>
                      <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                          End Date
                        </h4>
                        <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                          {`${localStorage.getItem("checkoutDate")}`}
                        </div>
                      </div>
                      <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                          Status
                        </h4>
                        <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                          -
                        </div>
                      </div>
                    </div>

                    <div className="border-b py-1"></div>
                    <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-3 pt-4">
                      <div className="p-1 ml-1">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                          Guests
                        </h4>
                        <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                          {localStorage.getItem("adultCount")} Adults | {localStorage.getItem("childrenCount")} Children |{' '}{localStorage.getItem("infantCount")} Infants
                        </div>
                      </div>
                      <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                          Rooms
                        </h4>
                        <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                          {localStorage.getItem("numberOfRooms")}
                        </div>
                      </div>
                      <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm  text-xs lg:text-xs md:text-xs mb-1">
                          Departure
                        </h4>
                        <div className="font-semibold font xl:text-sm  text-xs lg:text-xs md:text-xs">
                          {localStorage.getItem("departure")}
                        </div>
                      </div>
                    </div>
                    <div className="border-b py-1"></div>

                    <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-2 pt-4">
                      {/* <div className="p-1 ">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                          Booking Branch
                        </h4>
                        <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                          Bangalore
                        </div>
                      </div> */}
                      <div className="p-1 ml-1">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                          Booking Person
                        </h4>
                        <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                          {userName}
                        </div>
                      </div>
                      {/* <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                          Form Number
                        </h4>
                        <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                          DUBAITRAWEL2022
                        </div>
                      </div> */}
                    </div>

                    <div className="border-b py-1"></div>

                    <div className="grid xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 grid-cols-2 pt-4">
                      {/* <div className="p-1 ">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                          Tour Manager
                        </h4>
                        <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                          Kishore Kumar
                        </div>
                      </div> */}

                      {/* <div className="p-1 xl:ml-1 lg:ml-1 md:ml-1">
                        <h4 className="font1 font-medium text-neutral-500 xl:text-sm lg:text-xs md:text-xs text-xs">
                          Tour Manager No
                        </h4>
                        <div className="font-semibold font xl:text-sm lg:text-xs md:text-xs text-xs">
                          9876543210
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="border shadow rounded-md p-4 w-4/12 ">
                    <h1 className="text-sky-600 font text-base">
                      Payment
                    </h1>

                    <p className="xl:mt-6 text-sm font-semibold font mb-1">
                      Total Amount
                      <p className=""> ₹{localStorage.getItem("totalAmount") || ""} </p>
                    </p>
                    <p className="xl:mt-2 text-sm font-medium font mb-1">
                      Amount Paid
                      <p className=""> ₹0</p>
                    </p>
                    <p className="xl:mt-2 text-sm font-medium font flex justify-between">
                      Balance Amount
                      <p className=""> ₹{localStorage.getItem("totalAmount") || ""} </p>
                    </p>

                    <p
                      className='px-4 py-2 text-black rounded text-base font-semibold'
                      onClick={() => iropenModal(localStorage.getItem("packageId") ? localStorage.getItem("packageId") : null)}
                    >
                      View Price Breakup
                    </p>
                  </div>
                </div>
                {/* <section id="includ" className=" lg:block cursor-pointer">
                  {accordionData3.map((item, index) => (
                    <Accordion key={index} title={item.title} content={item.content} />
                  ))}
                </section> */}
              </p>

            </div>
          )}
          <Transition
            appear
            show={selectedItemId == localStorage.getItem("packageId") && selectedItemId != null}
            as={Fragment}
          >
            <Dialog
              as="div"
              className="relative z-10"
              onClose={ircloseModal}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0  bg-opacity-25" />
              </Transition.Child>
              <div className="font fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle transition-all">
                      <div className="text-right mt-2 mr-2">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={ircloseModal}
                        >
                          X
                        </button>
                      </div>
                      <Dialog.Title
                        as="h3"
                        className="font p-4 bg-[#fbf7fb] lg:-mt-12 md:-mt-9 -mt-12 lg:pt-6 text-base font-regular text-left leading-6 text-sky-600"
                      >
                        Trawel Mart's Detailed Tour Price
                        <p className="xl:text-xs font font-regular text-gray-500">
                          Prices & discounts are Per
                          Person for Indian Nationals
                          only.
                        </p>
                      </Dialog.Title>
                      <div className="p-4">
                        <table
                          key="table"
                          className="table"
                        >
                          <table
                            className="rounded-2xl"
                            style={{ width: "480px" }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border:
                                      "1px solid #d9d7d7",
                                    padding: "10px",
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    textAlign: "left",
                                    width: "230px",
                                  }}
                                >
                                  Premium Packages
                                </th>
                                <th
                                  style={{
                                    border:
                                      "1px solid #d9d7d7",
                                    padding: "10px",
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    textAlign: "center",
                                  }}
                                >
                                  Price
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    border:
                                      "1px solid #d9d7d7",
                                    padding: "10px",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    textAlign: "left",
                                    backgroundColor:
                                      "#f9f9f9",
                                    width: "230px",
                                  }}
                                >
                                  Adult
                                </td>
                                <td
                                  style={{
                                    border:
                                      "1px solid #d9d7d7",
                                    padding: "10px",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    backgroundColor:
                                      "#f9f9f9",
                                  }}
                                >
                                  {" "}
                                  {pricingData
                                    ? formatPrice(
                                      (
                                        pricingData as any
                                      ).adult
                                    )
                                    : "0"}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border:
                                      "1px solid #d9d7d7",
                                    padding: "10px",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    textAlign: "left",
                                    width: "210px",
                                  }}
                                >
                                  Child With Bed
                                </td>
                                <td
                                  style={{
                                    border:
                                      "1px solid #d9d7d7",
                                    padding: "10px",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  {pricingData
                                    ? formatPrice(
                                      (
                                        pricingData as any
                                      ).child
                                    )
                                    : "0"}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border:
                                      "1px solid #d9d7d7",
                                    padding: "10px",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    textAlign: "left",
                                    backgroundColor:
                                      "#f9f9f9",
                                    width: "230px",
                                  }}
                                >
                                  Child Without Bed
                                </td>
                                <td
                                  style={{
                                    border:
                                      "1px solid #d9d7d7",
                                    padding: "10px",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    backgroundColor:
                                      "#f9f9f9",
                                  }}
                                >
                                  {" "}
                                  {pricingData
                                    ? formatPrice(
                                      (
                                        pricingData as any
                                      ).infant
                                    )
                                    : "0"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </table>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (

      <div
        className="w-full relative border border-neutral-200 rounded-2xl"
        id="profile"
      >
        <div className="flex items-center justify-between bg-white border-b rounded-t-2xl px-6 pt-4 pb-4 ">
          <div>
            <h2 className="text-xl font font-semibold">My Account</h2>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              Basic info, for a faster booking experience
            </span>
          </div>
        </div>

        {/*<div className="px-5 pt-6 pb-4 bg-white">
          <h6 className="font1 font-semibold xl:text-base text-sky-600 pb-2">
            Login Details
          </h6>
          <div className="flex flex-wrap mt-2 ">
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 ">

              <input
                type="text"
                className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                placeholder="Phone Number"
                value={userMobileNumber}
              />
            </div>
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-2 py-2">

              <input
                type="text"
                className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                placeholder="Email ID"
                value={userEmail}
              />
            </div>
          </div>
        </div>*/}



        {/* Profile Details */}

        <div className="px-5 pt-6 pb-4 bg-white">
          <h6 className="font1 font-semibold xl:text-base text-sky-600 pb-2">
            Profile Details
          </h6>
          <div className="flex flex-wrap mt-2 ">
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 ">
              <input
                type="text"
                className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                placeholder="Name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-2 py-2">

              <input
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                className="text-left rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
              />
            </div>
          </div>
          <div className="flex flex-wrap xl:py-0 py-0 md:py-2">
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:mt-3">

              <select
                className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-2 py-2 xl:mt-3 ">

              <select className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                value={maritalStatus}
                onChange={(e) => setMaritalStatus(e.target.value)}>
                <option value="">Select Marital Status</option>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Widow / Widower">Widow / Widower</option>
                <option value="Divorced">Divorced</option>
              </select>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mt-3 xl:mr-6">

              <input
                type="text"
                className="text-sm rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-2 py-2 xl:mt-3">

              <input className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                placeholder="Pincode"
                type="text"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)} />
            </div>
          </div>
          <div className="flex flex-wrap xl:py-0 py-0 md:py-2">
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mt-3 xl:mr-6">

              <select className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0"
                value={addressState} onChange={(e) => setAddressState(e.target.value)}>
                <option value="">Select State</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
            </div>
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-2 py-2 xl:mt-3">

              <select className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                value={country}
                onChange={(e) => setCountry(e.target.value)}>
                <option value="">Select Country</option>
                <option value="India">India</option>
              </select>
            </div>
          </div>

        </div>

        <div className="px-5 pt-6 pb-4 bg-white">
          <h6 className="font1 font-semibold xl:text-base text-sky-600 pb-2">
            Passport Information
          </h6>
          <div className="mt-2 ">
            <div className="xl:w-10/12 lg:w-6/12 md:w-12/12 w-full ">
              <input
                type="text"
                className="rounded-md w-12/12 h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                placeholder="Passport Number"
                value={passportNumber}
                onChange={(e) => setPassportNumber(e.target.value)}

              />
            </div>
          </div>
          <div className="flex flex-wrap xl:py-0 py-0 md:py-2 xl:mt-2 xl:mb-6">
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-0 py-2 xl:mt-3 md:mt-2">
              <input
                type="date"
                value={passportExpiry}
                onChange={(e) => setPassportExpiry(e.target.value)}
                className="text-left rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
              />
            </div>
            <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-2 py-2 xl:mt-3 md:mt-2">
              <select className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                value={passportIssue}
                onChange={(e) => setPassportIssue(e.target.value)}>
                <option value="">Passport Issuing Country</option>
                <option value="India">India</option>
              </select>
            </div>
          </div>
        </div>

        <div className="px-5 pt-2 pb-4 bg-white">
          <h6 className="font1 font-semibold xl:text-base text-sky-600 pb-2">
            Document Upload
          </h6>
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 xl:gap-2 md:gap-2 lg:gap-2 gap-2">
            <div className="">
              <p className="font-semibold font text-sm">
                Passport Upload
              </p>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md"><div className="space-y-1 text-center" id="passport_file_holder_real" style={{ display: selectedPassportFile === '' ? 'block' : 'none' }}>
                <svg className="mx-auto h-12 w-12 text-neutral-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                <div className="flex xl:text-sm lg:text-xs md:text-xs text-neutral-6000 dark:text-neutral-300"><label htmlFor="passport-file-input" className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"><span>Upload a file</span><input type="file" className="sr-only" onChange={handlePassportFileChange} id="passport-file-input" name="passport-file-input" /></label><p className="pl-1">or drag and drop</p></div><p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF up to 10MB</p></div>
                <div className="space-y-1 text-center" id="passport_file_holder_hidden" style={{ display: selectedPassportFile === '' ? 'none' : 'block' }}>
                  <img src={'https://admin.trawelmart.com/admin/public/' + selectedPassportFile} alt="Passport File" id="passport_image_file" />
                  <a href='#!' onClick={deletePassportFile} className="btn btn-danger mt-2">Delete</a>
                </div>
              </div>
            </div>

            <div className="">
              <p className="font-semibold font text-sm">
                Aadhar Upload
              </p>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md"><div className="space-y-1 text-center" id="aadhar_file_holder_real" style={{ display: selectedAadharFile === '' ? 'block' : 'none' }}>
                <svg className="mx-auto h-12 w-12 text-neutral-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                <div className="flex xl:text-sm lg:text-xs md:text-xs text-neutral-6000 dark:text-neutral-300"><label htmlFor="aadhar-file-input" className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"><span>Upload a file</span><input type="file" className="sr-only" onChange={handleAadharFileChange} id="aadhar-file-input" name="aadhar-file-input" /></label><p className="pl-1">or drag and drop</p></div><p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF up to 10MB</p></div>
                <div className="space-y-1 text-center" id="aadhar_file_holder_hidden" style={{ display: selectedAadharFile === '' ? 'none' : 'block' }}>
                  <img src={'https://admin.trawelmart.com/admin/public/' + selectedAadharFile} alt="Aadhar File" id="aadhar_image_file" />
                  <a href='#!' onClick={deleteAadharFile} className="btn btn-danger mt-2">Delete</a>
                </div>
              </div>
            </div>

            <div className="">
              <p className="font-semibold font text-sm">
                PAN Upload
              </p>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md"><div className="space-y-1 text-center" id="pan_file_holder_real" style={{ display: selectedPanFile === '' ? 'block' : 'none' }}>
                <svg className="mx-auto h-12 w-12 text-neutral-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                <div className="flex xl:text-sm lg:text-xs md:text-xs text-neutral-6000 dark:text-neutral-300"><label htmlFor="pan-file-input" className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"><span>Upload a file</span><input type="file" className="sr-only" id="pan-file-input" name="pan-file-input" onChange={handlePanFileChange} /></label><p className="pl-1">or drag and drop</p></div><p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF up to 10MB</p></div>
                <div className="space-y-1 text-center" id="pan_file_holder_hidden" style={{ display: selectedPanFile === '' ? 'none' : 'block' }}>
                  <img src={"https://admin.trawelmart.com/admin/public/" + selectedPanFile} alt="Pan File" id="pan_image_file" />
                  <a href='#!' onClick={deletePanFile} className="btn btn-danger mt-2">Delete</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" ml-5 mb-6 mt-4">
          <button
            type="button"
            className="mr-3 inline-flex justify-center rounded-md border border-sky-600 px-8 py-1 text-sm font-medium text-sky-900 hover:bg-white focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
            onClick={updateProfile}
          >
            Save
          </button>
        </div>

        {/* Co- Travellers */}

        <div className="flex items-center justify-between px-6 pt-8 pb-1 ">
          <div>
            <h6 className="font1 font-semibold xl:text-base text-sky-600">
              Co-Travellers
            </h6>
          </div>
          <div>
            <button
              type="button"
              onClick={remove}
              className="rounded-2xl border border-sky-600   px-6 py-2 text-sm font-medium text-sky-600 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              Add
            </button>
          </div>
          <Transition appear show={onOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={add}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-xl h-96 transform overflow-y-scroll rounded-2xl bg-white  align-middle shadow-xl transition-all opacity-100 scale-100">
                      <Dialog.Title
                        as="h3"
                        className="font text-center p-4 bg-[#fbf7fb]  lg:pt-6 text-xl font-medium leading-6 text-blue-600 font-bold"
                      >
                        Add Travellers
                      </Dialog.Title>
                      <div className="p-6">
                        <div className="flex -mt-2">
                          <div className="lg:w-5/12 text-left font font-semibold">

                            <select className="rounded-md w-12/12 h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                              value={travelerSalutation} onChange={(e) => setTravelerSalutation(e.target.value)}>
                              <option value="">Saluatation</option>
                              <option value="Mr">Mr</option>
                              <option value="Miss">Miss</option>
                              <option value="Ms">Ms</option>
                              <option value="Mrs">Mrs</option>
                            </select>
                          </div>
                          <div className="lg:w-full w-full xl:mr-6 xl:pl-4 pl-2 text-left font font-semibold">

                            <Input className="rounded-md w-12/12 h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                              value={travelerName} onChange={(e) => setTravelerName(e.target.value)} placeholder="Name" />
                          </div>
                        </div>
                        <div className="flex mt-4">
                          <div className="lg:w-6/12 w-5/12 xl:mr-6 text-left font ">
                            <select className="rounded-md w-12/12 h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                              value={travelerGender} onChange={(e) => setTravelerGender(e.target.value)}>
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                          <div className="lg:w-6/12 w-8/12 xl:mr-6 xl:pl-0 pl-2 lg:mb-0 mb-12 text-left font font-semibold">
                            <Input type="date" defaultValue="MM/YY" className="rounded-md w-12/12 h-9 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                              value={travelerAge} onChange={(e) => setTravelerAge(e.target.value)} />
                          </div>
                        </div>
                      </div>

                      <div className="px-5 pt-4 pb-4 bg-white text-left">
                        <h6 className="font1 font-semibold xl:text-base text-sky-600 pb-2">
                          Passport Information
                        </h6>
                        <div className="mt-2 ">
                          <div className="xl:w-11/12 lg:w-6/12 md:w-12/12 w-full ">
                            <input
                              type="text"
                              className="rounded-md w-12/12 h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                              placeholder="Passport Number"
                              value={travelerPassportNumber}
                              onChange={(e) => setTravelerPassportNumber(e.target.value)}

                            />
                          </div>
                        </div>
                        <div className="flex flex-wrap xl:py-0 py-0 md:py-2 xl:mt-2 xl:mb-6">
                          <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-0 py-2 xl:mt-3 md:mt-2">
                            <input
                              type="date"
                              value={travelerPassportExpiry}
                              onChange={(e) => setTravelerPassportExpiry(e.target.value)}
                              className="text-left rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                            />
                          </div>
                          <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-2 py-2 xl:mt-3 md:mt-2">
                            <select className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                              value={travelerPassportIssue}
                              onChange={(e) => setTravelerPassportIssue(e.target.value)}>
                              <option value="">Passport Issuing Country</option>
                              <option value="India">India</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="px-5 pt-2 pb-4 bg-white">
                        <h6 className="font1 font-semibold xl:text-base text-left text-sky-600 pb-2">
                          Document Upload
                        </h6>
                        <div className="grid xl:grid-cols-1 xl:gap-2">
                          <div className="">
                            <p className="font-semibold text-left font text-sm pb-2">
                              Passport Upload
                            </p>
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md"><div className="space-y-1 text-center" id="traveler_passport_file_holder_real" style={{ display: travelerSelectedPassportFile === '' ? 'block' : 'none' }}>
                              <svg className="mx-auto h-12 w-12 text-neutral-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              <div className="flex text-sm text-neutral-6000 dark:text-neutral-300"><label htmlFor="traveler_passport_file_input" className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"><span>Upload a file</span><input id="traveler_passport_file_input" name="traveler_passport_file_input" type="file" className="sr-only" onChange={handleTravelerPassportFileChange} /></label><p className="pl-1">or drag and drop</p></div><p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF up to 10MB</p></div>
                              <div className="space-y-1 text-center" id="traveler_passport_file_holder_hidden" style={{ display: travelerSelectedPassportFile === '' ? 'none' : 'block' }}>
                                <img src={"https://admin.trawelmart.com/admin/public/" + travelerSelectedPassportFile} alt="Passport File" id="traveler_passport_image_file" />
                                <a href='#!' onClick={deleteTravelerPassportFile} className="btn btn-danger mt-2">Delete</a>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <p className="font-semibold font text-left  text-sm  pt-4 pb-2">
                              Aadhar Upload
                            </p>
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md"><div className="space-y-1 text-center" id="traveler_aadhar_file_holder_real" style={{ display: travelerSelectedAadharFile === '' ? 'block' : 'none' }}>
                              <svg className="mx-auto h-12 w-12 text-neutral-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              <div className="flex text-sm text-neutral-6000 dark:text-neutral-300"><label htmlFor="traveler_aadhar_file_input" className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"><span>Upload a file</span><input id="traveler_aadhar_file_input" name="traveler_aadhar_file_input" type="file" className="sr-only" onChange={handleTravelerAadharFileChange} /></label><p className="pl-1">or drag and drop</p></div><p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF up to 10MB</p></div>
                              <div className="space-y-1 text-center" id="traveler_aadhar_file_holder_hidden" style={{ display: travelerSelectedAadharFile === '' ? 'none' : 'block' }}>
                                <img src={'https://admin.trawelmart.com/admin/public/' + travelerSelectedAadharFile} alt="Aadhar File" id="traveler_aadhar_image_file" />
                                <a href='#!' onClick={deleteTravelerAadharFile} className="btn btn-danger mt-2">Delete</a>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <p className="font-semibold font text-left  pt-4   pb-2  text-sm">
                              PAN Upload
                            </p>
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md"><div className="space-y-1 text-center" id="traveler_pan_file_holder_real" style={{ display: travelerSelectedPanFile === '' ? 'block' : 'none' }}>
                              <svg className="mx-auto h-12 w-12 text-neutral-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              <div className="flex text-sm text-neutral-6000 dark:text-neutral-300"><label htmlFor="traveler_pan_file_input" className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 text-sm"><span>Upload a file</span><input id="traveler_pan_file_input" name="traveler_pan_file_input" type="file" className="sr-only" onChange={handleTravelerPanFileChange} /></label><p className="pl-1">or drag and drop</p></div><p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF up to 10MB</p></div>
                              <div className="space-y-1 text-center" id="traveler_pan_file_holder_hidden" style={{ display: travelerSelectedPanFile === '' ? 'none' : 'block' }}>
                                <img src={'https://admin.trawelmart.com/admin/public/' + travelerSelectedPanFile} alt="Pan File" id="traveler_pan_image_file" />
                                <a href='#!' onClick={deleteTravelerPanFile} className="btn btn-danger mt-2">Delete</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="flex justify-end lg:mt-8 xl:mb-6 md:mb-6 md:mr-4 xl:mr-4 mr-4 mb-4">
                        <button
                          type="button"
                          className="mr-3 w-28 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={add}
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className="inline-flex w-28 justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={addTraveler}
                        >
                          Add
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
        <Transition appear show={onEditOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={edit}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-xl h-96 transform overflow-y-scroll rounded-2xl bg-white  align-middle shadow-xl transition-all opacity-100 scale-100">
                    <Dialog.Title
                      as="h3"
                      className="font text-center p-4 bg-[#fbf7fb]  lg:pt-6 text-xl font-medium leading-6 text-blue-600 font-bold"
                    >
                      Edit Traveller
                    </Dialog.Title>
                    <div className="p-6">
                      <div className="flex -mt-2">
                        <div className="lg:w-5/12 text-left font font-semibold">
                          <label className="lg:text-base text-neutral-600 font-semibold">Saluatation</label>
                          <select className="rounded-lg border lg:py-3 lg:w-11/12" value={editTravelerSalutation} onChange={(e) => setEditTravelerSalutation(e.target.value)}>
                            <option value="">Saluatation</option>
                            <option value="Mr"> Mr</option>
                            <option value="Miss"> Miss</option>
                            <option value="Ms"> Ms</option>
                            <option value="Mrs"> Mrs</option>
                          </select>
                        </div>
                        <div className="lg:w-full mr-6 text-left font font-semibold">
                          <label className="lg:text-base text-neutral-600 font-semibold">Name</label>
                          <Input className="border border-black rounded-lg" value={editTravelerName} onChange={(e) => setEditTravelerName(e.target.value)} />
                        </div>
                      </div>
                      <div className="flex mt-4">
                        <div className="lg:w-6/12 mr-6 text-left font font-semibold">
                          <label className="lg:text-base text-neutral-600 font-semibold">Gender</label>
                          <select className="rounded-lg border lg:py-3 lg:w-12/12" value={editTravelerGender} onChange={(e) => setEditTravelerGender(e.target.value)}>
                            <option value="">Select Gender</option>
                            <option value="Male"> Male</option>
                            <option value="Female"> Female</option>
                            <option value="Others"> Others</option>
                          </select>
                        </div>
                        <div className="lg:w-6/12 mr-6 lg:mb-6 text-left font font-semibold">
                          <label className="lg:text-base text-neutral-600 font-semibold">Age</label>
                          <Input type="date" defaultValue="MM/YY" className="border border-black rounded-lg" value={editTravelerAge} onChange={(e) => setEditTravelerAge(e.target.value)} />
                        </div>
                      </div>
                      <div className="px-5 pt-4 pb-4 bg-white text-left">
                        <h6 className="font1 font-semibold xl:text-base text-sky-600 pb-2">
                          Passport Information
                        </h6>
                        <div className="mt-2 ">
                          <div className="xl:w-11/12 lg:w-6/12 md:w-12/12 w-full ">
                            <input
                              type="text"
                              className="rounded-md w-12/12 h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                              placeholder="Passport Number"
                              value={editTravelerPassportNumber}
                              onChange={(e) => setEditTravelerPassportNumber(e.target.value)}

                            />
                          </div>
                        </div>
                        <div className="flex flex-wrap xl:py-0 py-0 md:py-2 xl:mt-2 xl:mb-6">
                          <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-0 py-2 xl:mt-3 md:mt-2">
                            <input
                              type="date"
                              value={editTravelerPassportExpiry}
                              onChange={(e) => setEditTravelerPassportExpiry(e.target.value)}
                              className="text-left rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                            />
                          </div>
                          <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 w-full xl:mr-6 xl:py-0 md:py-0 xl:pl-0 lg:pl-2 md:pl-2 py-2 xl:mt-3 md:mt-2">
                            <select className="rounded-md w-full h-10 border-slate-300 hover:border-sky-300 focus:border-sky-300 hover:outline-none focus:outline-none  focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-offset-0 "
                              value={editTravelerPassportIssue}
                              onChange={(e) => setEditTravelerPassportIssue(e.target.value)}>
                              <option value="">Passport Issuing Country</option>
                              <option value="India">India</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="px-5 pt-2 pb-4 bg-white">
                        <h6 className="font1 font-semibold xl:text-base text-left text-sky-600 pb-2">
                          Document Upload
                        </h6>
                        <div className="grid xl:grid-cols-1 xl:gap-2">
                          <div className="">
                            <p className="font-semibold text-left font text-sm pb-2">
                              Passport Upload
                            </p>
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md"><div className="space-y-1 text-center" id="traveler_passport_file_holder_real" style={{ display: travelerSelectedPassportFile === '' ? 'block' : 'none' }}>
                              <svg className="mx-auto h-12 w-12 text-neutral-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              <div className="flex text-sm text-neutral-6000 dark:text-neutral-300"><label htmlFor="traveler_passport_file_input" className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"><span>Upload a file</span><input id="traveler_passport_file_input" name="traveler_passport_file_input" type="file" className="sr-only" onChange={handleTravelerPassportFileChange} /></label><p className="pl-1">or drag and drop</p></div><p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF up to 10MB</p></div>
                              <div className="space-y-1 text-center" id="traveler_passport_file_holder_hidden" style={{ display: travelerSelectedPassportFile === '' ? 'none' : 'block' }}>
                                <img src={"https://admin.trawelmart.com/admin/public/" + travelerSelectedPassportFile} alt="Passport File" id="traveler_passport_image_file" />
                                <a href='#!' onClick={deleteTravelerPassportFile} className="btn btn-danger mt-2">Delete</a>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <p className="font-semibold font text-left  text-sm  pt-4 pb-2">
                              Aadhar Upload
                            </p>
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md"><div className="space-y-1 text-center" id="traveler_aadhar_file_holder_real" style={{ display: travelerSelectedAadharFile === '' ? 'block' : 'none' }}>
                              <svg className="mx-auto h-12 w-12 text-neutral-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              <div className="flex text-sm text-neutral-6000 dark:text-neutral-300"><label htmlFor="traveler_aadhar_file_input" className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"><span>Upload a file</span><input id="traveler_aadhar_file_input" name="traveler_aadhar_file_input" type="file" className="sr-only" onChange={handleTravelerAadharFileChange} /></label><p className="pl-1">or drag and drop</p></div><p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF up to 10MB</p></div>
                              <div className="space-y-1 text-center" id="traveler_aadhar_file_holder_hidden" style={{ display: travelerSelectedAadharFile === '' ? 'none' : 'block' }}>
                                <img src={'https://admin.trawelmart.com/admin/public/' + travelerSelectedAadharFile} alt="Aadhar File" id="traveler_aadhar_image_file" />
                                <a href='#!' onClick={deleteTravelerAadharFile} className="btn btn-danger mt-2">Delete</a>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <p className="font-semibold font text-left  pt-4   pb-2  text-sm">
                              PAN Upload
                            </p>
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md"><div className="space-y-1 text-center" id="traveler_pan_file_holder_real" style={{ display: travelerSelectedPanFile === '' ? 'block' : 'none' }}>
                              <svg className="mx-auto h-12 w-12 text-neutral-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              <div className="flex text-sm text-neutral-6000 dark:text-neutral-300"><label htmlFor="traveler_pan_file_input" className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 text-sm"><span>Upload a file</span><input id="traveler_pan_file_input" name="traveler_pan_file_input" type="file" className="sr-only" onChange={handleTravelerPanFileChange} /></label><p className="pl-1">or drag and drop</p></div><p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF up to 10MB</p></div>
                              <div className="space-y-1 text-center" id="traveler_pan_file_holder_hidden" style={{ display: travelerSelectedPanFile === '' ? 'none' : 'block' }}>
                                <img src={"https://admin.trawelmart.com/admin/public/" + travelerSelectedPanFile} alt="Pan File" id="traveler_pan_image_file" />
                                <a href='#!' onClick={deleteTravelerPanFile} className="btn btn-danger mt-2">Delete</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end lg:mt-3 xl:mb-6 xl:pr-6">
                      <button
                        type="button"
                        className="mr-3 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={edit}
                      >
                        Cancel
                      </button>

                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={updateTraveler}
                      >
                        Update
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <div className="px-6 pt-8 pb-8 w-full overflow-x-scroll xl:overflow-x-auto">
          <table style={{ border: '1px solid ;', width: '100% !important;' }} className="wid">
            <thead className="lg:w-12/12">
              <tr >
                <th style={{ border: '1px solid #ccc', padding: '8px' }}>S.No</th>
                <th style={{ border: '1px solid #ccc', padding: '8px', }} className="wid1">Saluatation</th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Name</th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Gender</th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Age</th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Edit</th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }}> View </th>
              </tr>
            </thead>
            <tbody>
              {travelers.map((item: any, index: any) => {

                const birthdate = new Date(item.age);
                const currentDate = new Date();
                const ageInMilliseconds = currentDate.getTime() - birthdate.getTime();
                const ageInYears = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));

                return (
                  <>
                    <tr key={index}>
                      <td style={{ border: '1px solid #ccc', padding: '8px;', textAlign: 'center' }}>{index + 1}</td>
                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>{item.salutation}</td>
                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>{item.name}</td>
                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>{item.gender}</td>
                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>{ageInYears}</td>
                      <td style={{ border: '1px solid #ccc', padding: '8px' }}><a href="#!" onClick={() => editData(index)}>Edit</a></td>
                      <td style={{ border: '1px solid #ccc', padding: '8px' }}><a href="#!" onClick={() => editData(index)}>View</a></td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Passport Inforamtion */}


      </div>
    );
  };


  const renderSection3 = () => {
    return (
      <div
        className="w-full relative border border-gray-300 rounded-lg bg-white p-4"
        id="travel"
      >
        <div className="grid xl:grid-cols-2 xl:gap-2 lg:grid-cols-2 lg:gap-2 md:grid-cols-2 md:gap-2 sm:grid-cols-2 sm:gap-2 items-center">
          <img src="https://i.ibb.co/KstCBfj/whislist.png" alt="" />
          <div className="justify-center center">
            <h6 className="font1 font-medium text-xl">
              You have not made any<br></br> bookings yet
            </h6>
            <a href="/">
              <button className="bg-sky-600 px-3 py-1 rounded-md mt-3  mb-6 text-white">
                Explore New Activities
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  };
  const renderSection4 = () => {
    return (
      <div
        className="w-full relative xl:mt-24 p-4"
        id="travel"
      >
        <div className="border border-neutral-300 rounded-md shadow-md p-4 xl:pt-6 xl:mt-0 lg:mt-0 mt-4 xl:text-xl bg-white">

          <div className="w-28 border-orange-500 rounded border uppercase xl:text-xs md:text-xs text-xs font1 p-1 text-orange-500 font-medium">
            Thailand Tour
          </div>
          <h5 className="text-left font1 font-bold xl:text-base lg:text-base md:text-base text-base pt-2 md:pt-2 xl:pt-2">
            Thailand 4 Nights 5 Days Tour Package
          </h5>

          <div className="text-left grid xl:grid-cols-2 xl:gap-12 lg:grid-cols-1 lg:gap-6 md:grid-cols-1 xl:mt-4 mt-2 grid-cols-1 items-center ">
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-3 gap-2">
              <div className="border-r p-1 ">
                <h4 className="font1 font-medium text-neutral-500 xl:text-sm text-xs lg:text-xs md:text-xs mb-1">
                  Booked on
                </h4>
                <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                  20 Dec 2023
                </div>
              </div>
              <div className="xl:border-r lg:border-r md:border-r p-1 ml-1">
                <h4 className="font1 font-medium text-neutral-500 xl:text-sm text-xs lg:text-xs md:text-xs mb-1">
                  Travelling Date
                </h4>
                <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                  20 - 25 Dec '23
                </div>
              </div>
              <div className="p-1 ml-1">
                <h4 className="font1 font-medium text-neutral-500 xl:text-sm text-xs lg:text-xs md:text-xs mb-1">
                  Tour Code
                </h4>
                <div className="font-semibold font xl:text-sm text-xs lg:text-xs md:text-xs">
                  MEMETRAWEL2023
                </div>
              </div>
            </div>
            <div className="flex xl:mt-0 lg:mt-0 md:mt-5 mt-4">
              <div className="xl:w-3/12">
              </div>
              <div className="flex xl:w-9/12 md:w-8/12 w-full">
                <button className="w-6/12  px-2 py-2 ">

                </button>
                <button className="w-6/12 bg-orange-400 text-white text-xs px-2 py-2  ml-2 rounded">
                  Pay Now
                </button>

              </div>
            </div>
          </div>

        </div>
      </div>
    );
  };




  return (
    <>
      <div className={`font nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
        <Helmet>
          <title>Account || Trawel Mart</title>
        </Helmet>
        <div className="xl:mt-12">

        </div>
        <main className="container w-full mt-3 mb-24 lg:mb-32  z-50 xl:mt-24 lg:mt-24 mt-36 md:mt-28">
          <div className="bg-white border flex flex-col lg:flex-row shadow rounded xl:py-20 xl:px-16">
            <div className="block xl:mb-24 lg:mb-24 mb-6 ml-5 xl:ml-0 lg:ml-0 md:ml-0 lg:mb-0 xl:block lg:block md:block sm:block block">
              <div className="lg:sticky lg:top-28">{renderSidebar()}</div>
            </div>
            <div className="w-full lg:w-9/12 xl:w-9/12 md:w-12/12 w-full space-y-8 lg:space-y-10 xl:pl-6 flex-shrink-0">

              {isAccountVisible &&
                <p>
                  {renderSection2()}
                </p>

              }
              {isTourVisible &&
                <p>
                  {renderSection1()}
                </p>
              }
              {isWishlistVisible &&
                <p>
                  {renderSection3()}
                </p>
              }
              {isCartVisible &&
                <p>
                  {renderSection4()}
                </p>
              }
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default AuthorPage;